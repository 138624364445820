import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useGet } from "../../helpers/axios/useApi";
import { Box, Grid, Typography } from "@mui/material";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function CalendarView({ size , getTimes}) {
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);
  const { data: getMeetings } = useGet('meetings/get', { month: currentMonth, year: currentYear });
  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const getEmployeeName = (id) => {
    if (!employees?.data?.message || !Array.isArray(employees?.data?.message[0])) {
      return "'Imperial Milestones";
    }
    if (!Array.isArray(id) || id.length === 0) {
      return "'Imperial Milestones";
    }
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id[0]
    );
    return employee ? `${employee.name}` : "'Imperial Milestones";
  };

  useEffect(() => {
    if (getMeetings?.data?.data) {
      const transformedEvents = getMeetings.data.data.map(meeting => {
        const start = new Date(meeting.meetingDate);
        const end = new Date(start.getTime() + meeting.meetingDuration * 60000); // Convert duration to milliseconds

        return {
          id: meeting._id,
          title: `${meeting.meetingName} -${meeting.meetingAgenda}-${meeting.meetingHost} - ${getEmployeeName(meeting.meetingBy)} - ${meeting.meetingDuration} minutes`,
          start,
          end,
          color: getColorByTimeDifference(start),
        };
      });
      setEvents(transformedEvents);
    }
  }, [getMeetings]);

  const getColorByTimeDifference = (eventStart) => {
    const now = new Date();
    const timeDiff = eventStart - now;
    const minutesDiff = Math.floor(timeDiff / 60000);

    if (minutesDiff < 10 && minutesDiff >= 0) {
      return "#e82e3b"; // Less than 10 minutes
    } else if (minutesDiff < 60 && minutesDiff >= 0) {
      return "#deb616"; // Less than 1 hour
    } else if (minutesDiff < 300 && minutesDiff >= 0) {
      return "green"; // Less than 5 hours
    } else if (minutesDiff >= 300) {
      return "#1ccb9e"; // Future event
    } else {
      return "#241f1f"; // Past event
    }
  };

  const handleNavigate = (date) => {
    setCurrentMonth(date.getMonth() + 1);
    setCurrentYear(date.getFullYear());
    getTimes(date.getMonth() + 1, date.getFullYear());
  };

  return (
    <>
         <Calendar
        localizer={localizer}
        events={events}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: event.color,
            color: "white",
          },
        })}
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        onNavigate={handleNavigate}
        style={{
          height: size.height,
          width: size.width,
          color: "grey",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "200",
          fontSize: "14px",
          border: "none",
        }}
      />
     
     
    </>
  );
}

export default CalendarView;
