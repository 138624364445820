import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';


/**
 * Displays a horizontal stepper to track the progress of the registration process.
 *
 * @prop {number} currentStage - The current stage of the registration process.
 * @prop {string[]} stages - An array of strings representing the stages of the registration process.
 *
 * @returns {React.ReactElement} - A horizontal stepper component with the appropriate stage highlighted.
 */

const ProgressTracker = ({ currentStage, stages }) => {
  return (
    <Stepper activeStep={currentStage} alternativeLabel sx={{width:'100%',color:'white'}}>
      {stages.map((stage, index) => (
        <Step key={index}>
          <StepLabel>{stage}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressTracker;
