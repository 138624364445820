import React, { useEffect, useState } from "react";
import CalendarView from "../../components/emplyoee/calenderView";
import { Box, Grid, Typography } from "@mui/material";
import ProfileNav from "../../components/user/profiveNav";
import { useGet } from "../../helpers/axios/useApi";
import { AccessTime, Event } from "@mui/icons-material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import InfoIcon from "@mui/icons-material/Info";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EventNoteIcon from "@mui/icons-material/EventNote";
import moment from "moment";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
function Calender() {
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);
  const { data: getMeetings } = useGet("meetings/get", {
    month: currentMonth,
    year: currentYear,
  });
  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  useEffect(() => {
    if (getMeetings?.data?.data) {
      setEvents(getMeetings.data.data);
    }
  }, [getMeetings]);
  console.log(events, currentMonth, currentYear);
  const getEmployeeName = (id) => {
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id
    );
    return employee ? `${employee.name}` : "'Imperial Milestones";
  };
console.log(events, 'events');
  const handleTimes = (month, year) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };
  return (
    <>
      <Box
        width={"93%"}
        height={"100vh"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          pt={3}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
          >
            <Grid item xs={12} md={12} container justifyContent="flex-end">
              <ProfileNav />
            </Grid>
          </Grid>
        </Box>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <Grid
            height={"85vh"}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              width: "65%",
              padding: "20px",
            }}
          >
            <CalendarView
              size={{
                width: "100%",
                height: "80vh",
              }}
              getTimes={handleTimes}
            />
          </Grid>
          <Grid
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              width: "33%",
              padding: "20px",
              mr: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "1rem",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "5px 20px ",
                boxShadow: "0 2px 0px rgba(0,0,0,0.1)",
                backgroundColor: "white",
              }}
            >
              Events{" "}
              <EventNoteIcon sx={{ fontSize: "1.5rem", color: "#edac07" }} />
            </Typography>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                mt: 1,
                gap: "0.5rem", // Increase gap for better separation
                backgroundColor: "white",
                height: "73vh",
                overflow: "scroll",
              }}
            >
              {events?.map((event) => (
                <Grid
                  item
                  xs={12} // Full width for each event
                  key={event._id} // Unique key for each event
                >
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                      lineHeight: 1.5,
                      padding: 2,
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 2,
                      boxShadow: 3,
                      transition: "0.3s", // Smooth hover effect
                      "&:hover": {
                        backgroundColor: "#f9f9f9", // Change background on hover
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <MeetingRoomIcon
                        sx={{ marginRight: 1, fontSize: "0.9rem" }}
                      />{" "}
                      {/* Event icon */}
                      <strong style={{ fontSize: "1rem" }}>
                        {event.meetingName}
                      </strong>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      marginRight={1}
                      gap={1}
                    >
                      <InfoIcon sx={{ marginRight: 0, fontSize: "1rem" }} />{" "}
                      <span style={{ fontSize: "1rem", fontSize: "0.9rem" }}>
                        <em style={{ fontSize: "1rem" }}>
                          {event.meetingAgenda}
                        </em>
                      </span>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <TimelapseIcon
                        sx={{ marginRight: 0.5, fontSize: "0.9rem" }}
                      />{" "}
                      {/* Clock icon */}
                      <strong style={{ marginRight: 0.5, fontSize: "0.9rem" }}>
                        {event.meetingDuration} min
                      </strong>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <HourglassBottomIcon
                        sx={{ marginRight: 0.5, fontSize: "0.9rem" }}
                      />{" "}
                      <strong style={{ fontSize: "0.9rem" }}>
                       {moment.utc(event?.meetingDate).format("DD-MM-YYYY - HH:mm")}
                      </strong>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <PersonPinIcon
                        sx={{ marginRight: 0, fontSize: "0.9rem" }}
                      />{" "}
                      <strong style={{ fontSize: "0.9rem" }}>
                        {getEmployeeName(event.meetingBy)}
                      </strong>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <LinkIcon sx={{ marginRight: 0, fontSize: "1.2rem", color: "green" }} />
                      <strong
                        style={{
                          fontSize: "0.9rem",
                          overflow: "hidden", // Hide overflow text
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          textOverflow: "ellipsis", // Show ellipsis for overflow
                          maxWidth: "300px", // Set a max width for the link container
                          display: "block", // Make it a block element
                        }}
                      >
                        <a
                          href={event.meetingLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.meetingLink}
                        </a>
                      </strong>
                    </Box>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Calender;
