import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import companyLogo from "../../../assets/images/logoCompany.jpeg";
import sign from "../../../assets/images/signCheck.png";

// Function to calculate total sales
const calculateTotalSales = (salesArray) => {
  return salesArray?.reduce((total, sale) => total + (sale?.sales || 0), 0) || 0;
};

// New Incentive Calculation Function
const calculateTotalIncentives = (totalData, payrollData) => {
  const Sale = calculateTotalSales(totalData);
  if (payrollData?.gstRate === 0) return 0;

  const totalSale = Sale / (1 + (payrollData?.gstRate / 100));
  const incentives = payrollData?.incentive;
  
  if (!incentives || incentives.length === 0) return 0;

  let totalIncentive = 0;
  let remainingSale = totalSale;

  incentives.forEach((incentive, index) => {
    const { minSalesAmount, maxSalesAmount, percentage } = incentive;
    if (remainingSale > minSalesAmount) {
      if (index === 0) {
        const salesInThisSlab = Math.min(remainingSale, maxSalesAmount);
        const incentiveAmount = (salesInThisSlab * percentage) / 100;
        totalIncentive += incentiveAmount;
        remainingSale -= salesInThisSlab;
      } else {
        const salesInThisSlab = Math.min(remainingSale, maxSalesAmount) - minSalesAmount;
        if (salesInThisSlab > 0) {
          const incentiveAmount = (salesInThisSlab * percentage) / 100;
          totalIncentive += incentiveAmount;
        }
        remainingSale -= salesInThisSlab;
      }
    }
    if (remainingSale <= 0) return;
  });

  return Math.round(totalIncentive);
};

// Function to render slab details
const renderSlabDetails = (totalData, payrollData) => {
  const Sale = calculateTotalSales(totalData);
  console.log(Sale,'Sale')
  const totalSale = Sale / (1 + (payrollData?.gstRate / 100));
  const incentives = payrollData?.incentive;
  if (!incentives || incentives.length === 0) return null;

  let remainingSale = totalSale;
  const slabDetails = incentives.map((incentive, index) => {
    const { minSalesAmount, maxSalesAmount, percentage } = incentive || {};
    let salesInThisSlab = 0;
    let incentiveAmount = 0;

    if (remainingSale > minSalesAmount) {
      if (index === 0) {
        salesInThisSlab = Math.min(remainingSale, maxSalesAmount);
        incentiveAmount = (salesInThisSlab * percentage) / 100;
        remainingSale -= salesInThisSlab;
      } else {
        salesInThisSlab = Math.min(remainingSale, maxSalesAmount) - minSalesAmount;
        if (salesInThisSlab > 0) {
          incentiveAmount = (salesInThisSlab * percentage) / 100;
        }
        remainingSale -= salesInThisSlab;
      }
    }

    return {
      minSalesAmount,
      maxSalesAmount,
      percentage,
      salesInThisSlab,
      incentiveAmount,
    };
  });

  return slabDetails.map((slab, index) => (
<>
        <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>
            Range
          </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
            ₹ {slab.minSalesAmount || "0"} - {slab.maxSalesAmount || "0"}
          </Typography>
        </Grid>
             <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
             <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>
               Incentive Percent
             </Typography>
             <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
               ₹ {slab.percentage || "0"}
             </Typography>
           </Grid>
           
             <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
             <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>
                Incentive Amount
             </Typography>
             <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
               ₹ {Math.round(slab.incentiveAmount) || "0"}
             </Typography>
           </Grid>
         </>
  ));
};

const SalarySlip = ({ payrollData, companyData, insativeData, employeeData , month, year}) => {
  const generatePDF = () => {
    const element = document.getElementById("salary-slip-content");
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "salary-slip.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2,
        logging: true,
        letterRendering: true,
        useCORS: true,
        backgroundColor: null,
      },
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
        pageMargins: [0.5, 0.5, 0.5, 0.5],
      },
    };
    html2pdf().from(element).set(options).save();
  };

  // Sales data (self and team)
  const selfSales = insativeData?.salesForEmployee || [];
  const teamSales = insativeData?.salesForJuniors || [];

  // Calculate incentives for self and team sales
  const selfIncentive = calculateTotalIncentives(selfSales, payrollData);
  const teamIncentive = calculateTotalIncentives(teamSales, payrollData);

  const getPreviousMonthDayCount = (base, deductions) => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const previousMonthDayCount = lastDayOfPreviousMonth.getDate();
    const parDay = base / previousMonthDayCount;
    const remainngDay = previousMonthDayCount - deductions;
    return parDay * remainngDay;
  };

  const netPay = Math.round(getPreviousMonthDayCount(payrollData?.basePay, payrollData?.deductions) + selfIncentive + teamIncentive + payrollData?.bonus);

  return (
    <div >


      <Box
        id="salary-slip-content"
        sx={{
            ...styles.pdfContainer,
            position: "relative",
            backgroundPosition: "center center", // Ensures the image is centered
            backgroundSize: "contain", // Ensures the image scales properly within the container
            backgroundRepeat: "no-repeat", // Prevents repeating the image
            backgroundAttachment: "scroll", // Fixes potential issues with `fixed` attachment
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.1)",
            borderRadius: "15px",
          }}
          
      >
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.97)",
            width:'103%',
            padding: "20px",
        }}>
        <Grid container spacing={0} sx={styles.header}>
          <Grid item xs={3} md={3}>
            <img
              src={companyData?.logo || companyLogo}
              alt="Company Logo"
              style={styles.logo}
            />
          </Grid>
          <Grid item xs={9} md={9} sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "right",
           
          }}>
            <Typography variant="h5" sx={{
                color: "Black",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "right",
            }}>
              {companyData?.companyName || "Company Name"}
            </Typography>
            <Typography sx={{
                color: "Black",
                textAlign: "right",
                fontSize: "12px",
                
            }}>{companyData?.companyAddress?.street || "Address not available"}</Typography>
            <Typography sx={{
                color: "Black",
                textAlign: "right",
                fontSize: "12px"
            }}>{companyData?.phone || "Phone not available"}</Typography>
            <Typography sx={{
                color: "Black",
                textAlign: "right",
                fontSize: "12px"
            }}>{companyData?.email || "Email not available"}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} md={4} sx={{ display: "flex", alignItems: "center", gap: 1, backgroundColor:'#ace3b4', padding:1, mt:1, justifyContent:'center' }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Salary Slip for month {month} - {year}</Typography>
      </Grid>

  <Box sx={{ padding: 2 }}>
    <Grid container spacing={1}>
      {/* Employee Name */}
      <Grid item xs={12} sm={9} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize:'12px' , color :'grey'}}>Employee Name : </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize:'13px' }}>{employeeData?.name || "Not available"}</Typography>
      </Grid>

      {/* Designation */}
      <Grid item xs={12} sm={12} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Designation : </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize:'13px' }}>{employeeData?.position || "Not available"}</Typography>
      </Grid>

      {/* Employee ID */}
      <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Employee ID :</Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{employeeData?.empId || "Not available"}</Typography>
      </Grid>

      {/* Bank Name */}
      <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Bank Name : </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{employeeData?.bankDetails?.bankName || "Not available"}</Typography>
      </Grid>

      {/* Account Number */}
      <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Account Number : </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{employeeData?.bankDetails?.accountNumber || "Not available"}</Typography>
      </Grid>

      {/* IFSC Code */}
      <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>IFSC Code :</Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{employeeData?.bankDetails?.ifscCode || "Not available"}</Typography>
      </Grid>

      {/* Branch */}
      <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Branch : </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{employeeData?.bankDetails?.branch || "Not available"}</Typography>
      </Grid>
    </Grid>
  </Box>
  <Box sx={{ padding: 2 }}>
    <Grid container spacing={0}>
      {/* Base Pay */}
      <Grid item xs={12} sm={2} md={4} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Base Pay: </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>₹{payrollData?.basePay || "0"}</Typography>
      </Grid>

      {/* Bonus */}
      <Grid item xs={12} sm={2} md={4} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Bonus: </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>₹{payrollData?.bonus || "0"}</Typography>
      </Grid>

      {/* Deductions */}
      <Grid item xs={12} sm={2} md={4} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Deductions: </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px'}}>{payrollData?.deductions || "0"} Day</Typography>
      </Grid>

      {/* Additional Allowances */}
      {payrollData?.additionalAllowances?.length > 0 &&
        payrollData.additionalAllowances.map((allowance, index) => (
          <Grid item xs={12} sm={2} md={4} key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>{allowance.name || "Allowance Name"}: </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize:'13px' }}>₹{allowance.value || "0"}</Typography>
          </Grid>
        ))}

      {/* Additional Deductions */}
      {payrollData?.additionalDeductions?.length > 0 &&
        payrollData.additionalDeductions.map((deduction, index) => (
          <Grid item xs={12} sm={2} md={4} key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>{deduction.name || "Deduction Name"}: </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize:'13px' }}>₹{deduction.value || "0"}</Typography>
          </Grid>
        ))}

      {/* Net Pay */}
      <Grid item xs={12} sm={2} md={4} sx={{ display: "flex", alignItems: "center", gap: 1, backgroundColor:'#ace3b4', padding:1, mt:1, justifyContent:'center' }}>
        <Typography variant="subtitle1"  sx={{ fontSize:'12px' , color :'grey'}}>Net Pay: </Typography>
        <Typography sx={{ fontWeight: "bold" , fontSize:'13px', color:'black'}}>₹{netPay || "0"}</Typography>
      </Grid>
    </Grid>
  </Box>

{/* Sales and Incentives Section */}
<Typography variant="h6" sx={styles.sectionTitle}>
  Sales and Incentives
</Typography>

  <Box sx={{ padding: 2 }}>
    <Grid container spacing={2}>
      {/* Total Self Sales */}
      <Grid item xs={12} sm={3} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>Total Self Sales: </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
          ₹{calculateTotalSales(selfSales)}
        </Typography>
      </Grid>

      {/* Total Sale By Team */}
      <Grid item xs={12} sm={3} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>Total Sale By Team: </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
          ₹{calculateTotalSales(teamSales)}
        </Typography>
      </Grid>

      {/* Total Self Sales Incentive */}
      <Grid item xs={12} sm={3} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>Total Self Sales Incentive: </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
          ₹{selfIncentive}
        </Typography>
      </Grid>

      {/* Total Sale By Team Incentive */}
      <Grid item xs={12} sm={3} md={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'grey' }}>Total Sale By Team Incentive: </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: '13px' }}>
          ₹{teamIncentive}
        </Typography>
      </Grid>
    </Grid>
  </Box>
  <Box sx={{ padding: 2 }}>
  <Typography variant="h6" sx={{
    fontSize: '14px',
    textAlign: 'left',
    color: '#34495e',
    fontWeight: 'bold',
  }}>By Self</Typography>
    <Grid container spacing={1}>
       
      {renderSlabDetails(selfSales, payrollData)
   }
    </Grid>
  </Box>
  <Box sx={{ padding: 2 }}>
  <Typography variant="h6" sx={{
    fontSize: '14px',
    textAlign: 'left',
    color: '#34495e',
    fontWeight: 'bold',
  }}>By Team</Typography>
    <Grid container spacing={1}>
      {renderSlabDetails(teamSales, payrollData)
      }
    </Grid>
  </Box>




        <Box
  sx={{
  
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    padding: "10px",
    flexDirection: "column", 
    maxWidth: "100%", // Align text in the center below the image
    ml: 50
   
  }}
>
  {/* Signature Image */}
  <img 
    src={sign} 
    alt="Signature" 
    style={{
      width: "100%",
      maxHeight: "50px", // Limit height if necessary
      objectFit: "contain", // Ensure the image scales correctly without distortion
    }} 
  />
  
  {/* Signature Label */}
  <Typography 
    variant="body2" 
    sx={{
      marginTop: "5px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#555", // Darker color for readability
    }}
  >
    Authorized Signature
  </Typography>
</Box>
</Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={generatePDF}
        sx={{
          marginBottom: "0px",
          mt:2,
          fontSize: "16px",
          borderRadius: "8px",
          height: 'max-content',
          boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
          "&:hover": {
            backgroundColor: "#003366",
            boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        Download Salary Slip
      </Button>
    </div>
  );
};

const styles = {
    pdfContainer: {
      padding: "30px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.35)",
      borderRadius: "20px",
      position: "relative",
      // Watermark logo setup
      backgroundImage: `url(${companyLogo})`, // Company logo as the background
      backgroundPosition: "center center", // Center the logo in the background
      backgroundSize: "38%", // Adjust the size of the watermark (smaller than full size)
      backgroundRepeat: "no-repeat", // Do not repeat the image
      backgroundAttachment: "fixed", // Fix the background so it doesn't scroll with content
      opacity: 0.99, // Adjust opacity to make it look like a watermark
    },
    logo: {
      maxWidth: "250px",
      maxHeight: "100px",
      display: "block",
      margin: "0 auto",
    },
    companyName: {
      fontWeight: "bold",
      fontSize: "22px",
      color: "#2c3e50",
    },
    header: {
      marginBottom: "20px",
    },
    sectionTitle: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#34495e",
      marginBottom: "10px",
    },

  };
  
export default SalarySlip;
