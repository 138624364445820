import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { Select, MenuItem, FormControl, InputLabel, Grid, Paper, Box, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useGet } from '../../../helpers/axios/useApi';
import { useParams } from 'react-router-dom';

export default function PayrollChart() {
  const currentYear = DateTime.now().year;
  const currentMonth = DateTime.now().month;
  const id = useParams().id;

  const [year, setYear] = React.useState(currentYear);
  const [month, setMonth] = React.useState(); // Default to current month
  const [viewMode, setViewMode] = React.useState('months'); // 'months' or 'days'

  const [loading, setLoading] = React.useState(false); // Loading state
  const [chartData, setChartData] = React.useState([]); // State for chart data
  const [xAxisData, setXAxisData] = React.useState([]); // State for X-axis data

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Fetching sales data from the API
  const { data: sales, loading: salesLoading, refetch } = useGet('/company/payroll/total-sales-data', {
    employeeId: id,
    month: month,
    year: year
  });

  React.useEffect(() => {
    if (salesLoading) {
      setLoading(true);
    } else {
      setLoading(false);

      if (sales && sales?.data?.data) {
        // Default to empty arrays if sales data is missing or not in expected format
        const salesForEmployee = Array.isArray(sales.data.data.salesForEmployee) ? sales.data.data.salesForEmployee : [];
        const salesForJuniors = Array.isArray(sales.data.data.salesForJuniors) ? sales.data.data.salesForJuniors : [];

        if (viewMode === 'months') {
          const allMonths = months; // All 12 months
          
          // Initialize the data for all 12 months, starting with 0 sales
          const monthlySalesEmployee = allMonths.map((monthName) => {
            // Convert the date to month name and compare
            const monthData = salesForEmployee.find(item => DateTime.fromISO(item.date).toFormat('MMMM') === monthName);
            return monthData ? monthData.sales : 0;  // If there's no data for this month, return 0
          });
        
          const monthlySalesJuniors = allMonths.map((monthName) => {
            // Convert the date to month name and compare
            const monthData = salesForJuniors.find(item => DateTime.fromISO(item.date).toFormat('MMMM') === monthName);
            return monthData ? monthData.sales : 0;  // If there's no data for this month, return 0
          });
        
          setXAxisData(allMonths);
          setChartData([monthlySalesEmployee, monthlySalesJuniors]);  // Both employee and juniors
        }
        else if (viewMode === 'days') {
          const totalDaysInMonth = DateTime.fromObject({ year, month }).daysInMonth;
          const allDays = Array.from({ length: totalDaysInMonth }, (_, i) => i + 1); // [1, 2, ..., 31]

          // Initialize the data for all days of the month, starting with 0 sales
          const dailySalesEmployee = allDays.map((day) => {
            const dayData = salesForEmployee.find(item => DateTime.fromISO(item.date).day === day);
            return dayData ? dayData.sales : 0; // If there's no data for this day, return 0
          });

          const dailySalesJuniors = allDays.map((day) => {
            const dayData = salesForJuniors.find(item => DateTime.fromISO(item.date).day === day);
            return dayData ? dayData.sales : 0; // If there's no data for this day, return 0
          });

          setXAxisData(allDays);
          setChartData([dailySalesEmployee, dailySalesJuniors]); // Both employee and juniors
        }
      } else {
        // If sales data is missing or invalid, reset chart data
        setXAxisData([]);
        setChartData([]);
      }
    }
  }, [sales, viewMode, month, year]); // Added `month` and `year` to the dependencies to refetch when they change

  // Handlers for user selection
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleViewModeChange = (event) => {
    const newViewMode = event.target.value;
    setViewMode(newViewMode);

    if (newViewMode === 'months') {
      setYear(currentYear); // Ensure year is current when switching to 'months' view
      setMonth(null);
      refetch(); // Refetch for months view
    } else if (newViewMode === 'days') {
      setMonth(currentMonth); // Reset to current month when switching to 'days' view
      refetch(); // Refetch for days view
    }
  };

  return (
    <Box sx={{ background: '#f5f5f5' }}>
      <Paper
        elevation={12}
        sx={{
          padding: 1.7,
          borderRadius: 3,
          background: '#ffffff',
          boxShadow: '0px 20px 60px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: 1200,
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0px 25px 100px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>
          <Grid container spacing={2} justifyContent="left" alignItems="center">
            {/* Year Selector */}
            <Grid item>
              <FormControl size="small" sx={{ width: 120 }}>
                <InputLabel sx={{ fontSize: '12px' }}>Year</InputLabel>
                <Select
                  value={year}
                  onChange={handleYearChange}
                  label="Year"
                  size="small"
                  disabled={viewMode === 'days'} // Disable year selector when in "By Days" view
                  sx={{
                    '& .MuiSelect-select': {
                      padding: '3px 7px',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {[...Array(5)].map((_, idx) => {
                    const currentYearOption = currentYear - idx;
                    return (
                      <MenuItem key={currentYearOption} value={currentYearOption}>
                        {currentYearOption}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Month Selector (only in 'days' mode) */}
            {viewMode === 'days' && (
              <Grid item>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel sx={{ fontSize: '12px' }}>Month</InputLabel>
                  <Select
                    value={month}
                    onChange={handleMonthChange}
                    label="Month"
                    size="small"
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '3px 7px',
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    {months.map((monthName, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {monthName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* View Mode Selector */}
            <Grid item>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: '12px' }}>View Mode</InputLabel>
                <Select
                  value={viewMode}
                  onChange={handleViewModeChange}
                  label="View Mode"
                  size="small"
                  sx={{
                    '& .MuiSelect-select': {
                      padding: '3px 7px',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <MenuItem value="months">By Months</MenuItem>
                  <MenuItem value="days">By Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Loading Indicator */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <ApexCharts
              options={{
                chart: {
                  type: 'area',
                  height: 550,
                  zoom: { enabled: false },
                  animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradients: true,
                    dynamicAnimation: {
                      speed: 350,
                    },
                  },
                },
                xaxis: {
                  categories: xAxisData,
                  title: {
                    text: viewMode === 'months' ? 'Month' : 'Date',  // Label as "Month" for month view
                    style: {
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#333',
                    },
                  },
                  labels: {
                    rotate: -45,  // Rotate the X-axis labels 45 degrees for better fit
                    style: {
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                    },
                  },
                  tickPlacement: 'on',
                },
                yaxis: {
                  title: {
                    text: 'Sale Amount',
                    style: {
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#333',
                    },
                  },
                  labels: {
                    formatter: (value) => value ? value.toFixed(2) : 'N/A',  // Show 'N/A' for missing data
                  },
                },
                dataLabels: {
                  enabled: false, // Disable data labels on the chart
                },
                grid: {
                  show: true,
                  borderColor: '#ddd',
                  strokeDashArray: 3,
                  position: 'back',
                },
                markers: {
                  size: 6,
                  colors: ['#ff4560'],
                  strokeColor: '#fff',
                  strokeWidth: 2,
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  x: { show: true },
                  y: {
                    formatter: function (val) {
                      return val === null ? 'No Data' : `₹${val.toFixed(2)}`;
                    },
                  },
                  theme: 'dark', // Tooltip theme
                },
              }}
              series={[
                {
                  name: 'Employee Sales',
                  data: chartData[0], // Employee sales
                },
                {
                  name: 'Juniors Sales',
                  data: chartData[1], // Juniors sales
                },
              ]}
              type="area"
              height={450}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
