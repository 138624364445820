import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LeadsNav from '../../components/leads/leadsNav/listNav'
import LeadTableView from '../../components/leads/leadsViewType/leadTableView'
import { useGet } from '../../helpers/axios/useApi'
import Loading from '../../assets/Loading/Index'
import { useDispatch, useSelector } from 'react-redux'
import { setFilters } from '../../redux/actions/leads/filter'
import GridLead from '../../components/leads/leadsViewType/leadGridView'
import { SearchInput } from '../../components/search'
import ProfileNav from '../../components/user/profiveNav'

import { useNavigate } from 'react-router-dom'
import MapLeadView from '../../components/leads/leadsViewType/mapView'



function Leads() {
    const {data,refetch, isLoading} = useGet('leads/form-get',{},{},{queryKey:'leads'})
    const [leadData, setLeadData] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
          if(data?.data?.message[0]?.columns){
            dispatch(setFilters(data?.data?.message[0]?.columns))
          }
          else{
            refetch()
          }

    }, [data])


    const {toggle} = useSelector(state => state.leads)

    const handleFetched = (data) => {
        setLeadData(data)
    }

  
  return (
     <>
        {isLoading && <Loading/>}
         <Box width={'93%'} display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
         <Box
        pt={1}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0rem",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          
          <Grid item xs={12} md={toggle ? 12 : 12} container justifyContent="flex-end" >
          
            <ProfileNav />
          </Grid>
        </Grid>
      </Box>
              <Grid maxWidth={'100%'} mt={-1}>
              <LeadsNav/>  
              </Grid>
              <Grid width={'100%'} sx={{
                overflow: 'scroll',
                  backgroundColor: 'white',
                  padding: '0px',
                  borderRadius: '10px',
                  ml: '0px',
              }}>
                 {toggle === false && <LeadTableView fetched={(data) => handleFetched(data)}/>}
                 {toggle === true && 
                  <GridLead data={leadData} />
            
                 }
                 {toggle === null && <MapLeadView/>}
              </Grid>
         </Box>
     
     </>
  )
}

export default Leads
