import { Box, Divider, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CloudDownload, Cancel, Edit } from '@mui/icons-material';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SchoolIcon from '@mui/icons-material/School';
import { useGet, usePost } from '../../helpers/axios/useApi';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../middlewares/auth';

function BasicDetail() {
    const [open, setOpen] = useState(false);
    const [empDetails, setEmpDetails] = useState({});
    const [formData, setFormData] = useState({ ...empDetails });
    const [newFiles, setNewFiles] = useState([]);
    const { id } = useParams();
    const { data: emp, isLoading: empLoading, refetch } = useGet('employee/employee-details', { empId: id });
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const getDateDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();
        let years = end.getFullYear() - start.getFullYear();
        let months = end.getMonth() - start.getMonth();
        let days = end.getDate() - start.getDate();

        if (days < 0) {
            months -= 1;
            const lastMonth = new Date(end.getFullYear(), end.getMonth(), 0);
            days += lastMonth.getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        return { years, months };
    };
    const difference = getDateDifference(empDetails?.joiningDate);
    
    useEffect(() => {
        if (emp?.data?.data) {
            setEmpDetails(emp.data.data);
        }
    }, [emp]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const { mutateAsync: updateEmployee } = usePost("employee/update");
    
    const handleOpen = () => {
        setOpen(true);
        setFormData({ ...empDetails });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setNewFiles([...newFiles, ...Array.from(e.target.files)]);
    };

    const handleSubmit = async () => {
        const updatedFiles = [...(empDetails?.files || []), ...newFiles.map(file => ({
            name: file.name,
            url: URL.createObjectURL(file), // Placeholder for the actual upload URL
            thumbnail: 'default-thumbnail.png',
        }))];
    
        const updateData = {
            employeeId: empDetails?._id,
            dob: formatDate(formData.dob),
            address: {
                city: formData.city,
                state: formData.state,
                country: formData.country,
                street: formData.street,
                zip: formData.zip
            }
        };
        
        await updateEmployee({ updateData });

        refetch();
        handleClose();
    };

    return (
        <>
            <Box sx={{
                width: '100%',
                borderRadius: '15px',
                padding: '20px',
                background: 'linear-gradient(135deg, #e3f2fd, #dfdff2)',
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                height: '60vh',
                mt: '-10px',
                overflow: 'auto',
            }}>
                <Grid>
                    <Grid display={'flex'} justifyContent={'space-between'}>
                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#0d47a1' }}>Basic Information</Typography>
                        <IconButton onClick={handleOpen} sx={{ color: '#0c64a8', display: user.role === "superAdmin" || user.role === "Admin" || user.role === "Manager" ? "flex" : "none", }}>
                            <Edit fontSize='large' />
                        </IconButton>
                    </Grid>
                    
                    <Grid sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', marginTop: '10px' }}>
                        <CardItem label="Join Date" value={formatDate(empDetails?.joiningDate)} />
                        <CardItem label="Experience" value={`${difference.months} mm, ${difference.years} yy`} />
                        <CardItem label="Employee ID" value={empDetails?.empId} />
                    </Grid>
                </Grid>
                
                <Divider sx={{ marginTop: '20px' }} />
                
                <Grid mt={2}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#0d47a1' }}>Personal Information</Typography>
                    <Grid sx={{ display: 'flex', gap: '20px', marginTop: '10px', width: '100%', alignItems: 'center' }}>
                        <Grid>
                        <CardItem label="DOB" value={formatDate(empDetails?.dob) || 'NA'} sx={{ width: '30%', height: '100%' }} />
                        </Grid>
                        <Grid sx={{ width: '70%' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>Address</Typography>
                            <Grid display={'flex'} gap={1} flexWrap={'wrap'}>
                                {['street', 'city', 'state', 'country', 'zip'].map((field) => (
                                    <CardItem key={field} value={empDetails?.address?.[field] || 'NA'} />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Divider sx={{ marginTop: '20px' }} />
                
                <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>Bank Details</Typography>
                    <Grid display={'flex'} gap={1} flexWrap={'wrap'}>
                        {['bankName', 'accountNumber', 'ifscCode', 'branch'].map((field) => (
                            <CardItem key={field} value={empDetails?.bankDetails?.[field] || 'NA'} />
                        ))}
                    </Grid>
                </Grid>
                
                <Divider sx={{ marginTop: '20px' }} />
                
                {/* File Section */}
                <Grid mt={2} display={'flex'} flexDirection={'column'} gap={2}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#0d47a1' }}>Occupation Details</Typography>
                    <Grid display={'flex'} gap={3} justifyContent={'space-around'}>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'black' }}>Documents</Typography>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <IconButton sx={{
                                    color: '#0c64a8',
                                    transition: 'transform 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.2)',
                                    }
                                }}>
                                    <FolderCopyIcon sx={{ fontSize: '50px' }} />
                                </IconButton>

                                {/* Circular Avatars for each file */}
                                {empDetails?.files?.map((file, index) => (
                                    <IconButton
                                        key={index}
                                        component="a"
                                        href={file.url}
                                        download
                                        sx={{
                                            position: 'absolute',
                                            left: `${70 + index * 60}px`,
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            transition: 'opacity 0.3s',
                                            '&:hover': {
                                                opacity: 0.8,
                                            },
                                        }}
                                    >
                                        <img
                                            src={file.thumbnail || 'default-thumbnail.png'}
                                            alt={`File ${index + 1}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    </IconButton>
                                ))}
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-input"
                                />
                                <label htmlFor="file-input">
                                    <Button variant="contained" component="span" sx={{ marginLeft: '20px', backgroundColor: '#0c64a8', '&:hover': { backgroundColor: '#005b8a' } }}>
                                        <CloudDownload sx={{ marginRight: '5px' }} />
                                        Upload Files
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* Dialog for Editing Details */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ backgroundColor: '#0c64a8', color: '#fff' }}>Edit Details</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="dob"
                        label="Date of Birth"
                        type="date"
                        fullWidth
                        value={formData.dob || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="street"
                        label="Street"
                        type="text"
                        fullWidth
                        value={formData.street || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="city"
                        label="City"
                        type="text"
                        fullWidth
                        value={formData.city || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="state"
                        label="State"
                        type="text"
                        fullWidth
                        value={formData.state || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="country"
                        label="Country"
                        type="text"
                        fullWidth
                        value={formData.country || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="zip"
                        label="Zip Code"
                        type="text"
                        fullWidth
                        value={formData.zip || ''}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
                        {isSubmitting ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const CardItem = ({ label, value }) => (
    <Box sx={{
        padding: '10px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.3)',
        },
        textAlign: 'center',
    }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#666' }}>{label}</Typography>
        <Typography variant="body1" sx={{ fontWeight: 'medium', color: '#000' }}>{value}</Typography>
    </Box>
);

export default BasicDetail;
