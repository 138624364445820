import React, { useEffect, useState } from "react";
import {
  Add,
  FilterAlt,
  GridView,
  ListAlt,
  ReplayOutlined,
  Settings,
  UploadFile,
  CalendarToday,
  Filter,
  Filter1,
  Map,
  DownloadSharp,
} from "@mui/icons-material";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PolylineIcon from '@mui/icons-material/Polyline';
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Box, Grid, IconButton, Typography, TextField, Button, Drawer, FormControl, Checkbox, FormControlLabel, Modal, Backdrop } from "@mui/material";
import CustomTooltip from "../customTooltip";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, toggleColumn, reorderColumns } from "../../../redux/actions/leads/filter";
import AddColumnFilterModal from "../sub-components/createWorkFlow";
import FileUploadButton from "../sub-components/uploadData";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddLeads from "../sub-components/addLeads";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ErrorBoundary from "../../../helpers/axios/error";
import { useNavigate } from "react-router-dom";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import * as XLSX from 'xlsx';
import { useAuth } from "../../../middlewares/auth";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import ColumnFilterPage from "../sub-components/filters";
import { SearchInput } from "../../search";
/**
 * Function to reorder columns in the list view
 * @param {array} list - The list of columns
 * @param {number} startIndex - The index of the column to move
 * @param {number} endIndex - The index of the column to move to
 * @return {array} The reordered list of columns
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function LeadsNav() {
  const [active, setActive] = useState("list");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [dateRangeText, setDateRangeText] = useState("All dates");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useAuth()
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.leads.filters || {});
  const columns = useSelector((state) => state.leads.column || []);
  const navigate  = useNavigate();
  const [filterModel, setFilterModel] = useState(false);
 const [workflowName,setWorkflowName] = useState('')
  const {toggle} = useSelector(state => state.leads)
  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectionRange(selection);
    setDateRangeText(
      `${selection.startDate.toDateString()} - ${selection.endDate.toDateString()}`
    );
  };

  const { downloadData } = useSelector(
    (state) => state.leads
  );

  
  const handleDownload = () => {
 
    // Ensure leads data is available
    if (!downloadData || downloadData.length === 0) {
      alert("No leads data available for download.");
      return;
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert leads data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(downloadData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a blob and trigger the download
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'leads.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const handleClose = () => {
    setShowDatePicker(false);
    setDateRangeText("All dates");
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
   dispatch({
      type: 'APPLY_RANGE_FILTER',
      payload: null
    })
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("startDate");
    urlParams.delete("endDate");

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAddLead = () => {
    setOpen(false);
  };


  const { mutateAsync: createForm, isLoading: createFormLoading } = usePost('/leads/form-create');
  const { data, isLoading , refetch} = useGet('/leads/form-get',{},{},{queryKey:'leadForm'});
  useEffect(() => {
    if (data) {
      setWorkflowName(data?.data?.message[0]?.formName || '');
    }
  }, [open, data]);
  const handleApply = () => {
    setDateRangeText(`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`);
    setShowDatePicker(false);
    dispatch({
      type: 'APPLY_RANGE_FILTER',
      payload: selectionRange
    })
    const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("startDate");
      urlParams.delete("endDate");

      urlParams.append("startDate", selectionRange?.startDate);
      urlParams.append("endDate", selectionRange?.endDate);

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const handleSaveFilters = async () => {


    try {
      if (workflowName && columns.length > 0) {
        const formFieldData = {
          formName: workflowName,
          columns: columns
        }
        const result = await createForm(formFieldData);
        if (result.data) {
          toast.success('Workflow updated successfully!');
          localStorage.removeItem('workflowName');
          localStorage.removeItem('columns');
          refetch();
          handleClose();
          setDrawerOpen(false);
        } else {
          toast.error(result.error.error);
        }
      } else {
        throw new Error('Workflow name or columns data missing.');
      }
    } catch (error) {
      console.error('Failed to create workflow.');
    } 
  };

  const toggleButton = (buttonType) => {
    const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    setActive(buttonType);
    if (buttonType === "list") {
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: false
    })
    } else if (buttonType === "map"){
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: null
    })
    } else {
      dispatch({
        type: 'LEAD_TOGGLE',
        payload: true
    })
    }
  };

  const handleFilterOpen = () => {
    setFilterModel(true);
  };

  const handleFilterClose = () => {
    setFilterModel(false);
  };
  
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    dispatch(setFilters({ ...filters, [name]: value }));
  };

  const handleApplyFilters = () => {
    setDrawerOpen(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleToggleColumn = (columnId) => {
    const column = columns.find(col => col.id === columnId);
    if (column) {
      dispatch(toggleColumn(columnId, !column.visible));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedColumns = reorder(
      columns,
      result.source.index,
      result.destination.index
    );
    dispatch(reorderColumns(reorderedColumns));
  };
  const handleRefetch = () => {
    dispatch({
      type: 'REFETCH_LEADS',
      payload: true
  })
  }
  function debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  const handleSearch = debounce((value) => {
    dispatch({
      type: 'REFETCH_LEADS',
      payload: true,
    });
  
    const urlParams = new URLSearchParams(window.location.search);
  
    if (value) {
      // Set or update the keyword if value is provided
      urlParams.set("keyword", value);
      urlParams.set("page", 1);
    } else {
      // Remove the keyword from the URL if value is empty
      urlParams.delete("keyword");
    }
  
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, 300);
  const handleDownloadSampleFile = () => {
    // Ensure leads data is available
    if (!columns || columns.length === 0) {
        alert("No leads data available for download.");
        return;
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Extract headers from the columns array
    const headers = columns.map(column => ({ [column.label]: '' }));

    // Convert headers to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(headers);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a blob and trigger the download
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'leads.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  return (
    <>
<Box
  sx={{
    display: { xs: "block", md: "flex" },
    width: "100%",
    gap: "1rem",
    justifyContent: { xs: "flex-start", md: "space-between" },
    borderRadius: "10px",
    marginTop: "30px",
    padding: { xs: "10px", md: "5px 30px" },
    backgroundColor: "white",
    alignItems: "center",
  }}
>
  <Grid container alignItems="center" spacing={0} width={{ xs: "100%", md: "32%" }}>
    <Grid item>
      <CustomTooltip title="Back">
        <IconButton
          onClick={() => window.history.back()}
          sx={{
            fontSize: { xs: "16px", md: "20px" },
            padding: "5px",
            borderRadius: "10%",
            border: 1,
            color: "black",
            mx: 1,
          }}
        >
          <FastRewindIcon sx={{ fontSize: '21px' }} />
        </IconButton>
      </CustomTooltip>
    </Grid>
    <Grid item>
      <Typography variant="h6">Leads</Typography>
    </Grid>
    <Grid item>
      <CustomTooltip title="Create Fields">
        <IconButton
          onClick={() => navigate('/leads/form/set-up-fields')}
          sx={{
            display: user.role === "Admin" || user.role === "Manager" || user.role === "superAdmin" ? "block" : "none",
            color: "#4287f5",
            fontSize: { xs: "12px", md: "14px" },
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid grey",
            padding: "5px 10px",
            mx: 1,
          }}
        >
          <PolylineIcon fontSize="12px" /> Set Up Fields
        </IconButton>
      </CustomTooltip>
    </Grid>
    <Grid item>
      <FileUploadButton />
    </Grid>
    <Grid item>
      <CustomTooltip title="Download sample file">
        <IconButton
          sx={{
            color: "black",
            fontSize: "16px",
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid grey",
            padding: "5px 10px",
          }}
          onClick={handleDownloadSampleFile}
        >
          <DownloadSharp fontSize="16px" />
        </IconButton>
      </CustomTooltip>
    </Grid>
  </Grid>

  <Grid item sx={{ borderRadius: "10px", border: '0.5px solid grey', padding: "0px 6px", flexGrow: 1 }}>
    <SearchInput onChange={handleSearch} />
  </Grid>

  <Grid item sx={{ mt: 2}}>
    <CustomTooltip title="Date Range">
      <Box sx={{ position: 'relative' }}>
        <TextField
          sx={{
            width: { xs: '100%', md: '200px' },
            '& .MuiInputBase-root': { height: '40px' },
            '& .MuiOutlinedInput-root': { paddingTop: '0px' },
          }}
          value={dateRangeText}
          readOnly
          label="Search by date"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => setShowDatePicker(!showDatePicker)}
                sx={{ padding: '0', marginLeft: '8px', color: '#4287f5' }}
              >
                <EventNoteIcon />
              </IconButton>
            ),
          }}
        />
        {showDatePicker && (
          <Box
            sx={{
              position: 'absolute',
              top: '110%',
              width: '80%',
              left: -400,
              zIndex: '999999999',
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              months={2}
              direction="horizontal"
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={false}
              onChange={handleSelect}
            />
            <Grid position={"absolute"} bottom={10} left={10} sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                sx={{ backgroundColor: "#4287f5", color: "white", padding: "2px 5px", fontSize: "12px" }}
                onClick={handleApply}
              >
                Apply
              </Button>
              <Button
                sx={{ backgroundColor: "#f52043", color: "white", padding: "2px 5px", fontSize: "12px" }}
                onClick={handleClose}
              >
                Reset
              </Button>
            </Grid>
          </Box>
        )}
      </Box>
    </CustomTooltip>
  </Grid>

  <Grid
    sx={{
      display: "flex",
      gap: "1rem",
      justifyContent: { xs: "flex-start", md: "space-around" },
      alignItems: "center",
    }}
  >
    <Grid item>
      <CustomTooltip title="Refresh">
        <IconButton
          onClick={handleRefetch}
          sx={{
            color: "black",
            fontSize: { xs: "14px", md: "16px" },
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid grey",
            padding: "5px 10px",
          }}
        >
          <ReplayOutlined fontSize="16px" />
        </IconButton>
      </CustomTooltip>
    </Grid>
    <Grid item>
      <CustomTooltip title="Add Lead">
        <IconButton
          onClick={handleClickOpen}
          sx={{
            color: "black",
            fontSize: { xs: "16px", md: "20px" },
            bgcolor: "#4287f5",
            padding: "5px",
          }}
        >
          <Add fontSize="16px" sx={{ color: "white" }} />
        </IconButton>
      </CustomTooltip>
    </Grid>
    {/* Toggle and Download Options */}
    <Grid item>
      <Grid
        sx={{
          border: "1px solid grey",
          borderRadius: "5px",
          overflow: "hidden",
          width: { xs: "100%", md: "auto" },
          display: "flex",
        }}
      >
        <CustomTooltip title="List View">
          <IconButton
            sx={{
              color: toggle !== false ? "black" : "white",
              fontSize: "16px",
              backgroundColor: toggle !== false ? "white" : "#4287f5",
              borderRadius: "0px",
              padding: "5px",
              width: "33%",
            }}
            onClick={() => toggleButton("list")}
          >
            <ListAlt fontSize="16px" />
          </IconButton>
        </CustomTooltip>
        <CustomTooltip title="Grid View">
          <IconButton
            sx={{
              color: toggle !== true ? "black" : "white",
              fontSize: "16px",
              backgroundColor: toggle !== true ? "white" : "#4287f5",
              borderRadius: "0px",
              padding: "5px",
              width: "33%",
            }}
            onClick={() => toggleButton("grid")}
          >
            <GridView fontSize="16px" />
          </IconButton>
        </CustomTooltip>
        <CustomTooltip title="Map View">
          <IconButton
            sx={{
              color: toggle !== null ? "black" : "white",
              fontSize: "16px",
              backgroundColor: toggle !== null ? "white" : "#4287f5",
              borderRadius: "0px",
              padding: "5px",
              width: "34%",
            }}
            onClick={() => toggleButton("map")}
          >
            <Map fontSize="16px" />
          </IconButton>
        </CustomTooltip>
      </Grid>
    </Grid>
    <Grid item>
      <CustomTooltip title="Excel">
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30px",
            height: "40px",
            borderRadius: "4px",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <MenuBookIcon
            onClick={handleDownload}
            sx={{
              fontSize: "30px",
              color: "#4287f5",
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "30%",
              left: "15%",
              color: "white",
              fontWeight: "bold",
              fontSize: "0.9rem",
            }}
          >
            X
          </Typography>
        </Box>
      </CustomTooltip>
    </Grid>
    <Grid item>
      <CustomTooltip title="Settings">
        <IconButton
          sx={{
            color: "black",
            fontSize: "16px",
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid grey",
            padding: "5px 10px",
          }}
          onClick={toggle ? handleFilterOpen : handleDrawerOpen}
        >
          {toggle ? <FilterAltIcon fontSize="16px" /> : <Settings fontSize="16px" />}
        </IconButton>
      </CustomTooltip>
    </Grid>
  </Grid>



        {/* Filter Drawer */}
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '16px',
            width: 250,
          },
        }}>
          <Box
            sx={{
              width: 250,
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6">Filter Leads</Typography>
            <Button variant="contained" onClick={handleSaveFilters}>Save new filters</Button>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {columns.map((column, index) => (
                      <Draggable key={column.id} draggableId={column.id} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{ mb: 1 }}
                          >
                            <FormControl fullWidth key={column.id} sx={{ mb: 0 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={column?.visible}
                                    onChange={() => handleToggleColumn(column.id)}
                                  />
                                }
                                label={column.label}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Drawer>
      </Box>
      <Drawer open={filterModel}
        onClose={handleFilterClose}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '16px',
            width: 250,
          },
        }}>
          <ColumnFilterPage />
      </Drawer>
      <AddColumnFilterModal open={openModal} onClose={handleCloseModal} />
      <ErrorBoundary>
      <AddLeads open={open} onClose={handleCloseAddLead} />
      </ErrorBoundary>
      
    </>
  );
}

export default LeadsNav;
