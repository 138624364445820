import axios from 'axios';
import axiosRetry from 'axios-retry';
import { server_url } from '../../utils/server';

/**
 * Create an Axios instance for API requests.
 * 
 * @type {axios.AxiosInstance}
 */
const apiClient = axios.create({
  baseURL: server_url,
  timeout: 150000, // 10 seconds timeout
  
});

/**
 * Configure retry behavior for the Axios instance.
 * 
 * @param {axios.AxiosInstance} apiClient - The Axios instance to configure.
 * @param {Object} options - The configuration options for axios-retry.
 * @param {number} options.retries - The number of retry attempts.
 * @param {function(number): number} options.retryDelay - A function to calculate the delay between retries.
 * @param {function(Error): boolean} options.retryCondition - A function to determine if a retry should occur.
 */
axiosRetry(apiClient, {
  retries: 1, // Number of retries
  /**
   * Calculate delay before retrying a request.
   * 
   * @param {number} retryCount - The number of retry attempts so far.
   * @returns {number} - The delay in milliseconds before the next retry.
   */
  retryDelay: (retryCount) => retryCount * 10000, // Delay between retries (in milliseconds)
  
  /**
   * Determine if the request should be retried based on the error.
   * 
   * @param {Error} error - The error encountered during the request.
   * @returns {boolean} - True if the request should be retried, false otherwise.
   */
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) ||
           (error.response && error.response.status >= 500);
  },
});

// Request Interceptor
/**
 * Add authorization headers and log request details.
 * 
 * @param {axios.AxiosRequestConfig} config - The Axios request configuration.
 * @returns {axios.AxiosRequestConfig} - The modified Axios request configuration.
 */
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
/**
 * Log response details and handle errors.
 * 
 * @param {axios.AxiosResponse} response - The Axios response.
 * @returns {axios.AxiosResponse} - The Axios response.
 */
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default apiClient;
