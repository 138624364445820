import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext";

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  
  return user !== null ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
