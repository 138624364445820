import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, MenuItem, Typography, IconButton, Tooltip } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, Edit as EditIcon, Height } from '@mui/icons-material';
import { useGet, usePost } from '../../../helpers/axios/useApi';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../../assets/Loading/Index';

// Function to generate ID from label
const generateIdFromLabel = (label) => {
  const cleanedLabel = label.trim().replace(/\s+/g, ' ');
  return cleanedLabel
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

// Save to local storage
const saveToLocalStorage = (workflowName, columns) => {
  localStorage.setItem('workflowName', workflowName);
  localStorage.setItem('columns', JSON.stringify(columns));
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  padding: '30px',
  height: '100vh',
  overflow: 'auto'
  
};

const columnInputStyle = {
  flex: 1,
  marginRight: '8px',
  fontSize: '12px',
};

const iconButtonStyle = {
  flexShrink: 0,
  marginLeft: '8px',
};

const AddColumnModal = ({ open, onClose }) => {
  const [workflowName, setWorkflowName] = useState('');
  const [columns, setColumns] = useState([]); // Initialize columns as an empty array
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { mutateAsync: createForm, isLoading: createFormLoading } = usePost('/leads/form-create');
  const { data, isLoading , refetch} = useGet('/leads/form-get',{},{},{queryKey:'leadForm'});

  useEffect(() => {
    if (data) {
      setWorkflowName(data?.data?.message[0]?.formName || '');
      setColumns(data?.data?.message[0]?.columns || []); // Default to empty array if undefined
    }
  }, [open, data]);

  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [field]: value };
    setColumns(updatedColumns);
  };

  const handleSaveColumn = (index) => {
    const updatedColumns = columns?.map((col, idx) =>
      idx === index ? { ...col, id: generateIdFromLabel(col.label), saved: true, editing: false } : col
    );
    setColumns(updatedColumns);
    saveToLocalStorage(workflowName, updatedColumns);
    alert('Column saved to local storage');
  };

  const handleRemoveColumn = (index) => {
    const updatedColumns = columns.filter((_, idx) => idx !== index);
    setColumns(updatedColumns);
    saveToLocalStorage(workflowName, updatedColumns);
  };

  const handleAddColumn = () => {
    setColumns([...columns, { id: '', label: '', type: 'text', options: [], visible: true, saved: false, editing: false }]);
  };

  const handleEditColumn = (index) => {
    const updatedColumns = columns?.map((col, idx) =>
      idx === index ? { ...col, editing: true } : col
    );
    setColumns(updatedColumns);
  };

  const handleSaveEdit = (index) => {
    const updatedColumns = columns?.map((col, idx) =>
      idx === index ? { ...col, saved: true, editing: false } : col
    );
    setColumns(updatedColumns);
    saveToLocalStorage(workflowName, updatedColumns);
  };

  const handleCancelEdit = (index) => {
    const updatedColumns = columns?.map((col, idx) =>
      idx === index ? { ...col, editing: false } : col
    );
    setColumns(updatedColumns);
  };

  const handleClose = () => {
    setWorkflowName('');
    setColumns([]);
    onClose();
  };

  const handleCreate = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      if (workflowName && columns.length > 0) {
        const formFieldData = {
          formName: workflowName,
          columns: columns
        }
        const result = await createForm(formFieldData);
        if (result.data) {
          toast.success('Workflow created successfully!');
          localStorage.removeItem('workflowName');
          localStorage.removeItem('columns');
          refetch();
          handleClose();
        } else {
          toast.error(result.error.error);
        }
      } else {
        throw new Error('Workflow name or columns data missing.');
      }
    } catch (error) {
      setError('Failed to create workflow.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {createFormLoading && <Loading />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-column-modal"
        aria-describedby="modal-to-add-new-columns"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Add Column
          </Typography>
          <TextField
            fullWidth
            label="Workflow Name"
            value={workflowName}
            onChange={(e) => setWorkflowName(e.target.value)}
            sx={{ mb: 2, mt: 2 , fontSize: '10px'}}
            size="small"
            disabled={loading}
          />
          {columns?.map((column, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                fullWidth
                label="Column Label"
                value={column.label}
                onChange={(e) => handleColumnChange(index, 'label', e.target.value)}
                sx={columnInputStyle}
                size="small"
                disabled={!column.editing && column.saved}
              />
              <TextField
                fullWidth
                label="Field Type"
                select
                value={column.type}
                onChange={(e) => handleColumnChange(index, 'type', e.target.value)}
                sx={columnInputStyle}
                size="small"
                disabled={!column.editing && column.saved}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="select">Select</MenuItem>
                <MenuItem value="file">File</MenuItem>
              </TextField>
              {column.type === 'select' && (
                <TextField
                  fullWidth
                  label="Options (comma-separated)"
                  value={column.options.join(', ')}
                  onChange={(e) => handleColumnChange(index, 'options', e.target.value.split(',')?.map(opt => opt.trim()))}
                  sx={columnInputStyle}
                  size="small"
                  disabled={!column.editing && column.saved}
                />
              )}
              <TextField
                fullWidth
                label="Visibility"
                select
                value={column.visible}
                onChange={(e) => handleColumnChange(index, 'visible', e.target.value === 'true')}
                sx={columnInputStyle}
                size="small"
                
                disabled={!column.editing && column.saved}
              >
                <MenuItem value={true}>Visible</MenuItem>
                <MenuItem value={false}>Hidden</MenuItem>
              </TextField>
              <Tooltip title={column.saved ? (column.editing ? 'Save' : 'Edit') : 'Save'}>
                <span>
                  <IconButton
                    onClick={() => column.saved ? (column.editing ? handleSaveEdit(index) : handleEditColumn(index)) : handleSaveColumn(index)}
                    color={column.editing || !column.saved ? "primary" : "default"}
                    sx={iconButtonStyle}
                    disabled={loading}
                  >
                    {column.editing ? <SaveIcon /> : column.saved ? <EditIcon /> : <SaveIcon />}
                  </IconButton>
                </span>
              </Tooltip>
              {column.editing && (
                <Tooltip title="Cancel Edit">
                  <span>
                    <IconButton
                      onClick={() => handleCancelEdit(index)}
                      color="default"
                      sx={iconButtonStyle}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip title="Remove Column">
                <span>
                  <IconButton
                    onClick={() => handleRemoveColumn(index)}
                    color="error"
                    sx={iconButtonStyle}
                    disabled={loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          ))}
          <Button onClick={handleAddColumn} variant="contained" color="secondary" sx={{ mb: 2 }} disabled={loading}>
            Add Another Column
          </Button>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCreate} variant="contained" color="success" disabled={loading}>
              {loading ? 'Adding...' : 'Add'}
            </Button>
            <Button onClick={handleClose} variant="contained" color="primary" sx={{ ml: 2 }} disabled={loading}>
              Close
            </Button>
          </Box>
          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
          {success && <Typography color="success" sx={{ mt: 2 }}>{success}</Typography>}
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddColumnModal; // Fix the export statement
