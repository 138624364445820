import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [token, setToken] = useState(localStorage.getItem("authToken") || null);
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    const storedToken = localStorage.getItem("authToken");

    // Check if user data exists
    if (storedUserData) {
      const { data, expiry } = storedUserData;
      if (new Date().getTime() > expiry) {
        // Remove expired UserData
        localStorage.removeItem("user");
        setUser(null);
        if (location.pathname !== "/register") { // Only navigate if not on register page
          navigate("/login");
        }
      } else {
        setUser(data);
      }
    } else {
      
    }

    if (storedToken) {
      setToken(storedToken);
    }
  }, [navigate, location]);

  const login = (User, token) => {
    const expiry = new Date().getTime() + 12 * 3600000; // 12 hours expiration
    localStorage.setItem("user", JSON.stringify({ data: User, expiry }));
    localStorage.setItem("authToken", token);
    setUser(User);
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    setUser(null);
    setToken(null);
    navigate("/login"); // Redirect to login on logout
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
