
  


import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  Typography,
  Grid,
  Fade,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { DateTime } from "luxon";
import { useGet } from "../../../helpers/axios/useApi";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../middlewares/auth";
import axios from "axios";
import AttendancePolicy from "../../company/policys/attendencePolicy";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const CalendarViewAttendance = ({ getTimes, size }) => {
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [policy, setPolicy] = useState({});
  const id = useParams().id;

  const { data: dailyRecords, error: recordsError } = useGet("employee/work-tracking/daily-records", {
    userId: id,
    startDate: DateTime.fromObject({ year: currentYear, month: currentMonth, day: 1 }).toISODate(),
    endDate: DateTime.fromObject({ year: currentYear, month: currentMonth }).endOf("month").toISODate(),
  });

  const { data: policyData, error: policyError } = useGet("company/policy/attendece-get", { employeeId: id });

  useEffect(() => {
    if (policyData) {
      setPolicy(policyData?.data?.data);
    }
  }, [policyData]);

  const colorPalette = {
    attended: "#1ccb9e",
    holiday: "#ffb74d",
    absent: "#e82e3b",
    sandwichLeave: "#6a1b9a",
    weekend: "#ffccbc",
    halfDay: "#e06960",
  };

  const fetchHolidays = async (apiKey) => {
    const CALENDAR_ID = "en.indian#holiday@group.v.calendar.google.com";
    const url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${apiKey}&timeMin=${new Date().toISOString()}&singleEvents=true&orderBy=startTime`;

    try {
      const response = await axios.get(url);
      return response.data.items.map((event) => ({
        id: event.id,
        start: new Date(event.start.date || event?.start.dateTime),
        end: new Date(event.end.date || event.end.dateTime),
        title: event.summary,
        color: colorPalette.holiday,
      }));
    } catch (error) {
      return [];
    }
  };

  const processRecords = async () => {
    if (!dailyRecords || recordsError || !policy) {
      setLoading(false);
      return; // Early exit if data is not available
    }
  
    setLoading(true);
    const transformedEvents = [];
    const absentDates = [];
    const today = moment().startOf("day");
  
    // Ensure records exist before processing
    const records = dailyRecords.data?.data?.records || [];
    if (!Array.isArray(records)) {
      
      setLoading(false);
      return; // Exit if records are not valid
    }
  
    records.forEach((entry) => {
      const start = new Date(entry?.day);
      let color = colorPalette.absent;
  
      if (entry.totalWorkingTime > 0) {
        color = colorPalette.attended;
      } else if (entry.totalWorkingTime < policy.workingHours) {
        color = colorPalette.halfDay;
      }
  
      transformedEvents.push({
        id: entry.day,
        start,
        end: start,
        color,
      });
    });
  
    const attendanceDays = new Set(records.map((entry) => entry.day));
    const startDate = moment.min(records.map((entry) => moment(entry.day)));
  
    for (let date = today.clone(); date.isAfter(startDate); date.subtract(1, "days")) {
      const formattedDate = date.format("YYYY-MM-DD");

    
      // Check if the date is a working day and not in attendanceDays
      const isWorkingDay = policy[0]?.workingDays?.[date.day()];

      if (isWorkingDay && !attendanceDays.has(formattedDate)) {

          absentDates.push(formattedDate);
          transformedEvents.push({
            id: formattedDate,
            start: date.toDate(),
            end: date.toDate(),
            color: colorPalette.absent,
          });
        }
      

       else if (!isWorkingDay) {
        // Mark non-working days as holidays
        transformedEvents.push({
          id: formattedDate,
          start: date.toDate(),
          end: date.toDate(),
          color: colorPalette.weekend,
        });
      }
    }
  
    handleSandwichLeaves(transformedEvents, absentDates);
    const holidayEvents = await fetchHolidays("YOUR_GOOGLE_CALENDAR_API_KEY");
    setEvents([...transformedEvents, ...holidayEvents]);
    setLoading(false);
  };

  const handleSandwichLeaves = (events, absentDates) => {
    const sandwichLeaveDates = new Set();
    
    const nonWorkingDays = policy?.workingDays ? 
        Object.keys(policy?.workingDays).filter((day) => !policy?.workingDays[day]).map(Number) : [];
    
    if (nonWorkingDays.length > 0) {
        nonWorkingDays.forEach((nonWorkingDay) => {
            // Get the current date to calculate the week's start date
            const today = moment();
            const weekStart = today.clone().startOf('isoWeek'); // ISO week starts on Monday

            // Calculate the actual date for the non-working day
            const nonWorkingDate = weekStart.clone().add(nonWorkingDay, 'days');
            const dayBefore = nonWorkingDate.clone().subtract(2, 'days');
            const dayAfter = nonWorkingDate.clone().add(0, 'days');
            // Format dates for comparison
            const nonWorkingDateStr = nonWorkingDate.format('YYYY-MM-DD');
            const dayBeforeStr = dayBefore.format('YYYY-MM-DD');
            const dayAfterStr = dayAfter.format('YYYY-MM-DD');
            const currentNonWorkingDate = dayAfter.clone().subtract(1, 'days');

            // Check if both the day before and after are in absentDates
            if (absentDates.includes(dayBeforeStr) && absentDates.includes(dayAfterStr)) {
                sandwichLeaveDates.add(nonWorkingDateStr);
                sandwichLeaveDates.add(dayBeforeStr);
                sandwichLeaveDates.add(dayAfterStr);
                sandwichLeaveDates.add(currentNonWorkingDate.format('YYYY-MM-DD'));
            }
        });
    }

    // Add sandwich leave dates to events
    sandwichLeaveDates.forEach((date) => {
        events.push({
            id: date,
            start: moment(date).toDate(),
            end: moment(date).toDate(),
            color: colorPalette.sandwichLeave,
            isSandwichLeave: true,
        });
    });
};

  useEffect(() => {
    processRecords();
  }, [dailyRecords, currentYear, currentMonth, policy]);

  const handleNavigate = (date) => {
    setCurrentMonth(date.getMonth() + 1);
    setCurrentYear(date.getFullYear());
    getTimes(date.getMonth() + 1, date.getFullYear());
  };

  const eventPropGetter = (event) => ({
    style: {
      backgroundColor: event.color,
      border: "none",
      height: 50,
      display: "block",
      marginTop: "0px",
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
        padding: "20px",
      }}
    >
      <Fade in={!loading} timeout={1000}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Calendar
              localizer={localizer}
              events={events}
              defaultView="month"
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={eventPropGetter}
              onNavigate={handleNavigate}
              style={{ height: size.height }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Attendance Legend:</Typography>
                {["superAdmin", "Admin", "Manager"].includes(user.role) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    sx={{
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                      background: "linear-gradient(135deg, #e3f2fd, #dfdff2)",
                      color: "black",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: "0 8px 30px rgba(0, 0, 0, 0.3)",
                      },
                    }}
                  >
                    Create Policy
                  </Button>
                )}
              </Grid>

              <Box display="flex" flexDirection="row" gap={3} mt={1}>
                {Object.entries(colorPalette).map(([key, color]) => (
                  <Box display="flex" alignItems="center" key={key}>
                    <Box width={20} height={20} bgcolor={color} mr={1} />
                    <Typography>
                      {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Fade>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <AttendancePolicy />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CalendarViewAttendance;
