// src/MapComponent.js

import React, { useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Drawer, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

// Default marker icon settings
const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41],
});

// Available map types
const mapTypes = [
  { name: 'OpenStreetMap', url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' },
];

const MapComponent = ({ locations }) => {
  const initialPosition = locations.length > 0 
    ? [locations[0].latitude, locations[0].longitude] 
    : [0, 0];
  const zoomLevel = locations.length > 0 ? 6 : 2;

  const [selectedMapType, setSelectedMapType] = useState(mapTypes[0].url);
  const [showMarkers, setShowMarkers] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const clusterLocations = (locations) => {
    const clusterRadius = 0.01; 
    const clusters = [];

    locations.forEach(location => {
      const cluster = clusters.find(c => {
        const distance = Math.sqrt(
          Math.pow(c.latitude - location.latitude, 2) +
          Math.pow(c.longitude - location.longitude, 2)
        );
        return distance < clusterRadius;
      });

      if (cluster) {
        cluster.locations.push(location);
        cluster.latitude = (cluster.latitude + location.latitude) / 2;
        cluster.longitude = (cluster.longitude + location.longitude) / 2;
      } else {
        clusters.push({ 
          latitude: location.latitude, 
          longitude: location.longitude, 
          locations: [location] 
        });
      }
    });

    return clusters;
  };

  const clusteredLocations = useMemo(() => clusterLocations(locations), [locations]);

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }, []);

  const handleMarkerClick = (locations) => {
    setSelectedLocation(locations);
    setDrawerOpen(true); // Open the drawer on marker click
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedLocation(null);
  };

  return (
    <div style={{
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '15px',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
      padding: '0px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    }}>

        <MapContainer center={initialPosition} zoom={zoomLevel} style={{ height: '100vh', width: '100%' }}>
          <TileLayer url={selectedMapType} />
          {showMarkers && clusteredLocations.map((cluster, index) => (
            <ClusterMarker 
              key={index} 
              cluster={cluster} 
              locations={cluster.locations} 
              onClick={handleMarkerClick}
            />
          ))}
        </MapContainer>


      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ width: '300px', padding: '20px' }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseDrawer}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Location Details</Typography>
            </Toolbar>
          </AppBar>
          {selectedLocation ? (
            selectedLocation.map((loc, index) => (
              <div key={index} style={{ margin: '10px 0' }}>
                <Typography variant="h6">{loc.name}</Typography>
                <Typography>Coordinates: {loc.latitude}, {loc.longitude}</Typography>
              </div>
            ))
          ) : (
            <Typography>Select a marker to see details</Typography>
          )}
        </div>
      </Drawer>
    </div>
  );
};

// ClusterMarker component
const ClusterMarker = ({ cluster, locations, onClick }) => {
  const map = useMap();

  return (
    <Marker 
      position={[cluster.latitude, cluster.longitude]} 
      icon={customIcon}
      eventHandlers={{
        click: () => {
          const bounds = L.latLngBounds(locations.map(loc => [loc.latitude, loc.longitude]));
          map.fitBounds(bounds, { padding: [20, 20] }); // Adding padding for better visibility
          onClick(locations);
        }
      }}
    >
      <Popup>
        {locations.length > 1 ? 
          `${locations.length} locations` :
          `Location: ${locations[0].name} <br /> 
          Coordinates: ${locations[0].latitude}, ${locations[0].longitude}`
        }
      </Popup>
    </Marker>
  );
};

export default MapComponent;
