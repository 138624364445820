import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Collapse,
  Typography,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';

// Utility function to capitalize the first letter of each key
const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const RenderObject = ({ data = {}, level = 0 }) => {
  if (Array.isArray(data)) {
    return (
      <List disablePadding>
        {data.map((item, index) => (
          <RenderListItem
            key={index}
            data={item}
            level={level}
            name={`Item ${index + 1}`}
          />
        ))}
      </List>
    );
  }

  if (typeof data !== 'object' || data === null) {
    return null; // Handle non-object cases
  }

  const keys = Object.keys(data);

  return (
    <List disablePadding>
      {keys.map((key) => (
        <RenderListItem
          key={key}
          data={data[key]}
          level={level}
          name={capitalizeFirstLetter(
            key.replace(/([A-Z])/g, ' $1').trim()
          )}
        />
      ))}
    </List>
  );
};

const RenderListItem = ({ data, level, name }) => {
  const [open, setOpen] = React.useState(false);
  const isObject = typeof data === 'object' && data !== null;
  const isArray = Array.isArray(data);
  const isHttpLink = typeof data === 'string' && data.startsWith('http');

  const handleToggle = () => setOpen(!open);

  // Function to share the meeting link
  const shareLink = (platform) => {
    const url = encodeURIComponent(data); // Assuming data is the link
    let shareUrl;

    switch (platform) {
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${url}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Check out this link&body=${url}`;
        break;
      case 'instagram':
        alert('Please copy the link and share it on Instagram.');
        return;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ListItem button onClick={isObject ? handleToggle : undefined}>
        <ListItemText
          primary={name}
          secondary={
            isObject ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" color="textSecondary" style={{ marginRight: 8 }}>
                  Additional details
                </Typography>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            ) : (
              <Typography variant="body2">{data}</Typography>
            )
          }
        />
      </ListItem>
      {isHttpLink && (
        <div style={{ display: 'flex', gap: 8 }}>
          <IconButton onClick={() => shareLink('whatsapp')} size="small" sx={{
            color :"#54cf3c"
          }}> 
            <WhatsAppIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => shareLink('email')} size="small" sx={{
            color :"#e08b63"
          }}>
            <EmailIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => shareLink('instagram')} size="small" sx={{
            color :"#e063b0"
          }}>
            <InstagramIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      {(isObject || isArray) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <RenderObject data={data} level={level + 1} />
        </Collapse>
      )}
    </div>
  );
};

const ObjectList = ({ data }) => {
  return (
    <Paper
      style={{
        padding: 0,
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <RenderObject data={data} />
    </Paper>
  );
};

export default ObjectList;
