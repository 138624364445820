import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import OfflineAlert from './OfflineAlert';
import Navbar from './navbar/nav';
import { LoginPage, RagisterUser } from './pages/auth';
import Employees from './pages/employee';
import Leads from './pages/leads';
import LeadDetails from './pages/leads/details';
import { PrivateRoute } from './middlewares/auth';
import Video from './pages/video';

import SetUpFileds from './pages/leads/setUpFileds';
import AdminRoute from './middlewares/auth/AdminRoute';
import FileUpload from './components/uploadFiles';
import EmplyoeeDetails from './pages/employee/details';
import Calender from './pages/calender';
import ScreenRecording from './pages/auth/puch-in-out';
import PayrollForm from './components/company/payrolleSetup';
const Nav = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    return (currentPath === '/login' || currentPath === '/register' ? null : <Navbar />);
}

const App = () => {
  

    const handleVideoEnd = () => {
        setLoading(false); // Set loading to false when the video ends
    };

    return (
        <>
         
            <OfflineAlert />
            <Nav />
            <Routes>
                {/* <Route path="/" element={<PayrollForm />} /> */}
                <Route path="/register" element={<RagisterUser />} /> 
                <Route path="/login" element={<LoginPage />} />
                <Route path='/video' element={<Video />} />
               
                <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
                <Route path="/leads/:id" element={<PrivateRoute><LeadDetails /></PrivateRoute>} />
                <Route path="/employees" element={<PrivateRoute><AdminRoute><Employees /></AdminRoute></PrivateRoute>} />
                <Route path="/emplyoee/:id" element={<PrivateRoute><EmplyoeeDetails /></PrivateRoute>} />
                <Route path="/leads/form/set-up-fields" element={<PrivateRoute><AdminRoute><SetUpFileds /></AdminRoute></PrivateRoute>} />
                <Route path="/calendar" element={<PrivateRoute><Calender /></PrivateRoute>} />
            </Routes>
        </>
    );
};

export default App;
