import React, { createContext, useState, useContext } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme';

const ThemeContext = createContext();


  /**
   * A context provider that wraps the MUI ThemeProvider. It handles the state
   * of the theme and provides a function to toggle between light and dark themes.
   * The value of the context is an object with two props: `toggleTheme` and `mode`.
   * The `toggleTheme` function switches between light and dark themes.
   * The `mode` prop is a string that indicates the current theme, either 'light' or 'dark'.
   * @param {{children: React.ReactNode}} props
   * @returns {React.ReactElement}
   */

export function ThemeProvider({ children }) {
  const [mode, setMode] = useState('light');
  const theme = mode === 'light' ? lightTheme : darkTheme;

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);
