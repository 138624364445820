import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../../assets/Loading/Index";
import { useDispatch } from "react-redux";
import AlertComponent from "../../../helpers/axios/alert";
import { omit, pick } from "lodash";

// Define the base schema using Zod
const baseSchema = z.object({
  leadName: z.string().min(1, "Lead Name is required"),
  mobile: z
    .string()
    .min(10, "Mobile number must be at least 10 digits")
    .max(15, "Mobile number must be at most 15 digits"),
  source: z.string().min(1, "Source is required"),
  email: z.string().email("Invalid email address"),
  workFlow: z.string().min(1, "Work Flow is required"), // Base validation for workFlow
  type: z.string().min(1, "Type is required"),
});

// Function to generate ID from label

const generateIdFromLabel = (label) => {
  const cleanedLabel = label.trim().replace(/\s+/g, " ");

  // Check if the label contains more than one word
  if (cleanedLabel.includes(" ")) {
    return cleanedLabel
      .split(" ")
      .map(
        (word, index) =>
          index === 0
            ? word.toLowerCase() // First word in lowercase
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize subsequent words
      )
      .join("");
  }

  // Return the single word in lowercase if it's only one word
  return cleanedLabel.toLowerCase();
};

const source = [
  "Website",
  "Referral",
  "Social Media",
  "Email",
  "Phone Call",
  "Others",
];
const type = ["WARM", "COLD", "HOT"];

const AddLeads = ({ open, onClose , update}) => {
  const [dynamicFields, setDynamicFields] = useState([]);
  const [workFlowOptions, setWorkFlowOptions] = useState([]);
  const [workFlowValue, setWorkFlowValue] = useState(null);
  const [customValues, setCustomValues] = useState({});
  const { user } = useAuth();
  const [schema, setSchema] = useState(baseSchema);
  const [defaultValues, setDefaultValues] = useState({});
  const [alertState, setAlertState] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      leadName: "",
      mobile: "",
      source: "",
      email: "",
      workFlow: "",
      type: "",
      // Initialize dynamic fields as empty strings or null based on type
      ...defaultValues,
    },
  });

  const { data, isLoading , refatch} = useGet(
    "/leads/form-get",
    {},
    {},
    { queryKey: "leadForm" }
  );

  const { mutate: addLeads, isLoading: addLoading } = usePost("/leads/create");

 
  useEffect(() => {
    if (data) {
      const columns = data?.data?.message[0]?.columns || [];
      setDynamicFields(columns);
      setWorkFlowOptions([data?.data?.message[0]?.formName] || []);

      let dynamicSchema = baseSchema;
      let dynamicDefaultValues = {};

      columns.forEach((column) => {
        const fieldName = generateIdFromLabel(column?.label);

        if (column?.type === "text" || column?.type === "select") {
          dynamicSchema = dynamicSchema.extend({
            [fieldName]: z.string().optional(),
          });
        } else if (column?.type === "file") {
          dynamicSchema = dynamicSchema.extend({
            [fieldName]: z.any().optional(),
          });
        }

        dynamicDefaultValues[fieldName] = "";
      });

      setSchema(dynamicSchema);
      setDefaultValues(dynamicDefaultValues);
    }
  }, [data]);

  const onSubmit = async (formData) => {
  
      if (!formData) {
        throw new Error('No form data provided');
      }

      const mainFields = pick(formData, ["leadName", "mobile", "source", "email", "workFlow", "type"]);
      const userFields = omit(formData, Object.keys(mainFields));

      if (!mainFields || !userFields) {
        throw new Error('Missing main fields or user fields');
      }

      const leadData = {
        ...mainFields,
        userFields,
        reportingManager: user?.reportingManager || user?._id,
      };

      if (!leadData?.reportingManager) {
        throw new Error('Missing reporting manager');
      }

      if(leadData){
        const response = await addLeads({ leadData });
       if(response?.error){
       }
      

      setAlertState({
        open: true,
        message: response?.data?.message || 'Lead added successfully',
        severity: 'success',
      });

      dispatch({ type: "REFETCH_LEADS", payload: true });
      
      onClose();
      }
      
    
  };


const handleClose = () => {
    setAlertState(prevState => ({
      ...prevState,
      open: false,
    }));
  };


  return (
    <>
       
      {addLoading && <Loading />}
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { width: "90%", height: "90%" } }}
      >
        <AlertComponent
        alertState={alertState}
        handleClose={handleClose}
      />
      
        <DialogTitle sx={{ backgroundColor: "#155ed4", color: "white" }}>
          Add New Lead
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ maxHeight: "calc(90vh - 164px)", overflowY: "auto" }}
        >
          
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="leadName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Lead Name*"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.leadName}
                      helperText={errors.leadName?.message}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "20px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "grey",
                          },
                          "&:hover fieldset": {
                            borderColor: "primary.main",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: "grey",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Mobile*"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.mobile}
                      helperText={errors.mobile?.message}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "20px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "grey",
                          },
                          "&:hover fieldset": {
                            borderColor: "primary.main",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: "grey",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="source"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      freeSolo
                      options={
                        dynamicFields.find((f) => f.label === "source")
                          ?.options || source
                      }
                      getOptionLabel={(option) => option}
                      value={customValues.source || field.value || ""}
                      onChange={(_, value) => {
                        field.onChange(value);
                        setCustomValues((prev) => ({ ...prev, source: value }));
                      }}
                      onInputChange={(_, value) => {
                        setCustomValues((prev) => ({ ...prev, source: value }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Source*"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={!!errors.source}
                          helperText={errors.source?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                              "& fieldset": {
                                borderColor: "grey",
                              },
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "primary.main",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            "& .MuiFormLabel-root": {
                              color: "grey",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      freeSolo
                      options={
                        dynamicFields.find((f) => f.label === "type")
                          ?.options || type
                      }
                      getOptionLabel={(option) => option}
                      value={customValues.type || field.value || ""}
                      onChange={(_, value) => {
                        field.onChange(value);
                        setCustomValues((prev) => ({ ...prev, type: value }));
                      }}
                      onInputChange={(_, value) => {
                        setCustomValues((prev) => ({ ...prev, type: value }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type*"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={!!errors.type}
                          helperText={errors.type?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                              "& fieldset": {
                                borderColor: "grey",
                              },
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "primary.main",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            "& .MuiFormLabel-root": {
                              color: "grey",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email*"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "20px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "grey",
                          },
                          "&:hover fieldset": {
                            borderColor: "primary.main",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: "grey",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="workFlow"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      freeSolo
                      options={workFlowOptions}
                      getOptionLabel={(option) => option}
                      value={workFlowValue || field.value || ""}
                      onChange={(_, value) => {
                        field.onChange(value);
                        setWorkFlowValue(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Work Flow"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={!!errors.workFlow}
                          helperText={errors.workFlow?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                              "& fieldset": {
                                borderColor: "grey",
                              },
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "primary.main",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            "& .MuiFormLabel-root": {
                              color: "grey",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {workFlowValue &&
                dynamicFields
                  .filter(
                    (fieldConfig) =>
                      !defaultValues[generateIdFromLabel(fieldConfig.label)]
                  )
                  .map((fieldConfig, index) => {
                    if (["text", "select"].includes(fieldConfig.type)) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          {fieldConfig.type === "text" && (
                            <Controller
                              name={generateIdFromLabel(fieldConfig.label)}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label={fieldConfig.label}
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  error={
                                    !!errors[
                                      generateIdFromLabel(fieldConfig.label)
                                    ]
                                  }
                                  helperText={
                                    errors[
                                      generateIdFromLabel(fieldConfig.label)
                                    ]?.message
                                  }
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      borderRadius: "20px",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "grey",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "primary.main",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "primary.main",
                                        boxShadow:
                                          "0 4px 6px rgba(0, 0, 0, 0.1)",
                                      },
                                    },
                                    "& .MuiFormLabel-root": {
                                      color: "grey",
                                    },
                                  }}
                                />
                              )}
                            />
                          )}
                          {fieldConfig.type === "select" && (
                            <Controller
                              name={generateIdFromLabel(fieldConfig.label)}
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  freeSolo
                                  options={fieldConfig.options}
                                  getOptionLabel={(option) => option}
                                  value={
                                    customValues[
                                      generateIdFromLabel(fieldConfig.label)
                                    ] ||
                                    field.value ||
                                    ""
                                  }
                                  onChange={(_, value) => {
                                    field.onChange(value);
                                    setCustomValues((prev) => ({
                                      ...prev,
                                      [generateIdFromLabel(fieldConfig.label)]:
                                        value,
                                    }));
                                  }}
                                  onInputChange={(_, value) => {
                                    setCustomValues((prev) => ({
                                      ...prev,
                                      [generateIdFromLabel(fieldConfig.label)]:
                                        value,
                                    }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={fieldConfig.label}
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      error={
                                        !!errors[
                                          generateIdFromLabel(fieldConfig.label)
                                        ]
                                      }
                                      helperText={
                                        errors[
                                          generateIdFromLabel(fieldConfig.label)
                                        ]?.message
                                      }
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "20px",
                                          boxShadow:
                                            "0 2px 4px rgba(0, 0, 0, 0.3)",
                                          "& fieldset": {
                                            borderColor: "grey",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "primary.main",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "primary.main",
                                            boxShadow:
                                              "0 4px 6px rgba(0, 0, 0, 0.1)",
                                          },
                                        },
                                        "& .MuiFormLabel-root": {
                                          color: "grey",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </Grid>
                      );
                    }

                    if (fieldConfig.type === "file") {
                      return (
                        <Grid item xs={12} sm={12} key={index}>
                          <Controller
                            name={generateIdFromLabel(fieldConfig.label)}
                            control={control}
                            render={({ field }) => (
                              <div style={{ margin: "16px 0" }}>
                                <input
                                  accept="*"
                                  type="file"
                                  id={`file-upload-${index}`}
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    setValue(
                                      generateIdFromLabel(fieldConfig.label),
                                      file
                                    );
                                  }}
                                />
                                <label htmlFor={`file-upload-${index}`}>
                                  <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload File
                                  </Button>
                                </label>
                                {getValues(
                                  generateIdFromLabel(fieldConfig.label)
                                ) && (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {
                                      getValues(
                                        generateIdFromLabel(fieldConfig.label)
                                      ).name
                                    }
                                  </Typography>
                                )}
                              </div>
                            )}
                          />
                        </Grid>
                      );
                    }

                    return null;
                  })}
            </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              backgroundColor: "#fa3257",
              color: "white",
              "&:hover": {
                backgroundColor: "#f54969",
              },
              m: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              backgroundColor: "#155ed4",
              color: "white",
              "&:hover": {
                backgroundColor: "#155ed4",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      
      </Dialog>
    </>
  );
};

export default AddLeads;
