import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Grid,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import BasicDetail from './basicDetail';
import { useGet, usePost } from '../../helpers/axios/useApi';
import { useAuth } from '../../middlewares/auth';

function EmpDetails() {
    const [empDetails, setEmpDetails] = useState({});
    const [avatar, setAvatar] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const { mutateAsync: updateEmployee } = usePost("employee/update");
    const { id } = useParams();
    const { data: emp, refetch } = useGet(`employee/employee-details`, { empId: id });
    const {user} = useAuth();
    useEffect(() => {
        if (emp?.data?.data) {
            setEmpDetails(emp.data.data);
            setAvatar(emp.data.data.avatar);
            setFormData(emp.data.data);
        }
    }, [emp]);

    const handleAvatarChange = (event) => {
        const file = event.target?.files ? event.target.files[0] : null; // Access the first file directly
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Set the avatar to the file's data URL
                setIsUploaded(true);
                setIsEdit(true);
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    const handleEditClick = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        await updateEmployee({ employeeId: empDetails._id, ...formData });
        handleCloseModal();
        refetch();
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem", backgroundColor: "white", padding: "0.3rem", borderRadius: "15px" }}>
            <Grid className="card" sx={{ display: "flex", flexDirection: "row", gap: "1.5rem", background: 'linear-gradient(135deg, #e3f2fd, #dfdff2)', padding: "1rem", borderRadius: "15px" }}>
                <Grid className="card-inner">
                    <Avatar
                        className="avatar"
                        sx={{ width: 150, height: 150, fontSize: "4rem", color: "white", backgroundColor: "grey" }}
                        src={avatar}
                    />
                    <IconButton component="label" onClick={isEdit ? () => updateEmployee({ employeeId: empDetails._id }) : handleAvatarChange} sx={{
                        backgroundColor: isUploaded ? "#d1e7dd" : "#94c793",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        "&:hover": { backgroundColor: isUploaded ? "#c1e3d4" : "#81d180" },
                        marginTop: "1rem",
                    }}>
                      <input type="file" hidden accept="image/*" onChange={handleAvatarChange} />

                        {isEdit ? <UploadIcon sx={{ color: "black" }} /> : <EditIcon sx={{ color: "black" }} />}
                    </IconButton>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Grid sx={{ display: "flex", gap: "1rem" , }}>
                        <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>{empDetails.name}</Typography>
                        <Typography sx={{
                            color: "white",
                            fontSize: "1rem",
                            borderRadius: "5px",
                            textAlign: "center",
                            padding: "5px 10px",
                            backgroundColor: empDetails.status === "active" ? "green" : "#db2e34"
                        }}>{empDetails.status}</Typography>
                        <IconButton onClick={handleEditClick} sx={{
                            display: user.role === "superAdmin" || user.role === "Admin" || user.role === "Manager" ? "flex" : "none",
                        }}>
                            <EditIcon color='primary' />
                        </IconButton>
                    </Grid>
                    {/* Additional Employee Details */}
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Role: <span style={{ color: 'black' }}>{empDetails.role}</span></Typography>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Position: <span style={{ color: 'black' }}>{empDetails.position}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Email: <span style={{ color: 'black' }}>{empDetails.email}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Phone: <span style={{ color: 'black' }}>{empDetails.phone || "NA"}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", color: "#5c5e5d" }}>Company: <span style={{ color: 'black' }}>{empDetails.companyName || "NA"}</span></Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        <Link to={empDetails.socialMedia?.twitter} target="_blank">
                            <XIcon sx={{ fontSize: "1rem", color: "black", cursor: "pointer" }} />
                        </Link>
                        <Link to={empDetails.socialMedia?.linkedin} target="_blank">
                            <LinkedInIcon sx={{ fontSize: "1.3rem", color: "#0c72f0", cursor: "pointer" }} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            {/* Modal for Editing Employee Details */}
            <Dialog open={openModal} onClose={handleCloseModal} PaperProps={{ sx: { minWidth: 300, borderRadius: 5, boxShadow: 24, padding: 2, overflow: "scroll"} }}>
                <DialogTitle>Edit Employee Details</DialogTitle>
                <DialogContent>
                    <Grid sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "500px", height: "300px", overflow: "scroll" }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Role"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Position"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="position"
                            value={formData.position}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Phone"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="Twitter"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="twitter"
                            value={formData.socialMedia?.twitter || ''}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                        <TextField
                            margin="dense"
                            label="LinkedIn"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="linkedin"
                            value={formData.socialMedia?.linkedin || ''}
                            onChange={handleChange}
                            sx={{ borderRadius: "5px", "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "white", borderRadius: "15px" } } }}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
            <BasicDetail empDetails={empDetails} />
            
            <style jsx>{`
                .card {
                    perspective: 1000px;
                }
                .card-inner {
                    transition: transform 0.5s;
                    transform-style: preserve-3d;
                }
                .card:hover .card-inner {
                    transform: rotateY(10deg) rotateX(10deg);
                }
                .avatar {
                    transition: transform 0.5s;
                }
                .avatar:hover {
                    transform: scale(1.1);
                }
            `}</style>
        </Box>
    );
}

export default EmpDetails;
