import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  LogoutTwoTone,
  Dashboard,
  Leaderboard,
  CalendarMonth,
  Groups2,
  MessageRounded,
  FolderCopyRounded,
  ChatBubble,
  Login,
  ReceiptRounded,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import GlassEffect from '../theme/glassEffect';
import supportIMG from '../assets/images/support.png';
import logoIMG from '../assets/images/logo.png';
import { useAuth } from '../middlewares/auth';
import { Link, useLocation } from 'react-router-dom';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Spinner } from 'reactstrap';
import { usePost } from '../helpers/axios/useApi';
const NavbarContainer = styled('div')(({ theme, expanded }) => ({
  width: expanded ? '250px' : '80px',
  transition: 'width 0.3s ease',
  backgroundColor: 'transparent',
  borderRight: '1px solid rgba(255, 255, 255, 0.2)',
  height: '100vh',
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
}));

const ListItemStyled = styled(ListItem)(({ theme, active, disabled }) => ({
  backgroundColor: active ? 'rgba(295, 295, 295, 0.9)' : 'transparent',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : 'rgba(255, 255, 255, 0.2)',
  },
  position: 'relative',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.5 : 1,
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  '& svg': {
    fontSize: '1.5rem',
  },
}));



const Navbar = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user, logout } = useAuth();
  const location = useLocation();
  const navbarRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const pathToIndex = {
    '/dashboard': 0,
    '/leads': 1,
    '/customers': 2,
    '/calendar': 3,
    '/employees': 4,
    '/messages': 5,
    '/marketing': 6,
    '/invoices': 7,
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);
  useEffect(() => {
    const currentPath = location.pathname;
    let index = null;
    Object.keys(pathToIndex).forEach((path) => {
      if (currentPath === path || currentPath.startsWith(`${path}/`)) {
        index = pathToIndex[path];
      }
    });
    setSelectedIndex(index ?? 0);
  }, [location.pathname]);

  const handleListItemClick = async(index) => {
   await setSelectedIndex(index);
    setExpanded(false);
  };
  const createIp = usePost('/company/policy/ip-address-create');
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const getPublicIp = async () => {
    try {
      // Fetch the public IP using ipify
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;  // Return the public IP address
    } catch (error) {
      console.error('Error retrieving public IP:', error);
      return null;  // Return null in case of an error
    }
  };
  
  // Example usage:
  const createIpProtocal = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);
  
    try {
      // Step 1: Get the public IP address
      const publicIp = await getPublicIp();
      console.log(publicIp);
      
      if (!publicIp) {
        throw new Error('Failed to retrieve public IP address');
      }
  
      // Step 2: Send the IP address to the server
      const apiResponse = await createIp.mutateAsync({ routerIp: publicIp });
  
      if (apiResponse?.data !== null) {
      // Step 3: Handle the success response
      setSuccess(true);
      setSnackbarMessage('IP Protocol created successfully!');
      setSnackbarSeverity('success');
      }
      else{
        console.error('Failed to create IP Protocol', apiResponse);
        setSnackbarMessage(apiResponse?.error?.error || 'Failed to create IP Protocol');
        setSnackbarSeverity('error');
      }


    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };
  
  
  
  // Handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const drawer = (
    <NavbarContainer ref={navbarRef} expanded={expanded}>
      <GlassEffect.GlassContainer sx={{ height: '100%', position: 'sticky', top: 0 }}>
        <List sx={{ height: '100%' }}>
          <Grid
            height={'10%'}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <img src={logoIMG} alt="Logo" height={'70px'} width={'70px'} style={{ marginLeft: '10px' }} onClick={handleToggle}/>
            {expanded && (
              <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#155ed4' }}>
                {user?.loginId}
              </Typography>
            )}
          </Grid>
          <Grid minHeight={'60%'}>
            <>
              {Object.entries(pathToIndex).map(([path, index]) => {
                const isDisabled = index !== 1 && index !== 4 && index !== 3;
                return (
                  <Link to={isDisabled ? '#' : path} key={path} style={{ textDecoration: 'none' }}>
                    <ListItemStyled
                      button
                      selected={selectedIndex === index}
                      onClick={() => !isDisabled && handleListItemClick(index)}
                      active={selectedIndex === index}
                      disabled={isDisabled}
                    >
                      <IconContainer alignItems="center">
                        {index === 0 && (
                          <Dashboard
                            
                            sx={{
                              color: selectedIndex === index ? '#155ed4' : '#636161',
                              display:
                                user?.role === 'Admin' ||
                                user?.role === 'superAdmin' ||
                                user?.role === 'Manager'
                                  ? 'block'
                                  : 'none',
                            }}
                          />
                        )}
                        {index === 1 && <Leaderboard sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 2 && <Diversity2Icon sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 3 && <CalendarMonth sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 4 && <Groups2 sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 5 && <MessageRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 6 && <FolderCopyRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                        {index === 7 && <ReceiptRounded sx={{ color: selectedIndex === index ? '#155ed4' : '#636161' }} />}
                      </IconContainer>
                      {expanded && (
                        <ListItemText
                          primary={
                            index === 0
                              ? 'Dashboard'
                              : index === 1
                              ? 'Leads'
                              : index === 2
                              ? 'Customers'
                              : index === 3
                              ? 'Calendar'
                              : index === 4 &&
                                (user?.role === 'Admin' ||
                                  user?.role === 'superAdmin' ||
                                  user?.role === 'Manager')
                              ? 'Employees'
                              : index === 5
                              ? 'Messages'
                              : index === 6
                              ? 'Marketing'
                              : index === 7
                              ? 'Invoices'
                              : 'Info Portal'
                          }
                          sx={{
                            color: selectedIndex === index ? '#155ed4' : '#636161',
                            textAlign: 'start',
                          }}
                        />
                      )}
                    </ListItemStyled>
                  </Link>
                );
              })}
            </>
          </Grid>
          <Grid
            height={'10%'}
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '20px',
            }}
          >
            <img src={supportIMG} alt="Support" height={'100px'} width={'100px'} style={{ marginBottom: '10px' }} />
            {expanded && (
              <Button sx={{ textTransform: 'none' }}>
                <ChatBubble /> Support
              </Button>
            )}
          </Grid>
        
      {user?.role === 'Admin'  || user?.role === 'superAdmin'  ? (
           
      
      <Grid item>
        <Button
          onClick={createIpProtocal}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<WifiPasswordIcon />}
          disabled={loading} // Disable button when loading
          sx={{
            padding: '4px 8px',
            borderRadius: '8px',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            backgroundColor: '#1976d2', // Customize button color
            '&:hover': {
              backgroundColor: '#1565c0', // Customize hover color
            },
          }}
        >
          {loading && <CircularProgress size={20} color="inherit" />} 
        </Button>
      </Grid>
): null}
      {/* Snackbar for Success/Error feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
          },
          '& .MuiSnackbarContent-message': {
            color: 'white',
          },
          marginLeft: '150px',
          width:'100vh'
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          iconMapping={{
            success: <CheckCircleIcon />,
            error: <ErrorIcon />,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

          {user ? (
            <Grid
              height={'10%'}
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <Button variant="outlined" onClick={logout}>
                <LogoutTwoTone /> {expanded ? 'Logout' : ''}
              </Button>
            </Grid>
          ) : (
            <Grid
              height={'10%'}
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="outlined">
                  <Login /> {expanded ? 'Login' : ''}
                </Button>
              </Link>
            </Grid>
          )}
        </List>
      </GlassEffect.GlassContainer>
   
       
     
    </NavbarContainer>
  );

  return <nav>{drawer}</nav>;
};

export default Navbar;
