import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, Slide, Grid } from '@mui/material';
import TeamMember from './subComponents/teamMember';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Slide direction="up" in={value === index} mountOnEnter unmountOnExit>
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                </Slide>
            )}
        </div>
    );
};

const TeamTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', boxShadow: 3, borderRadius: 2, backgroundColor: 'white' , height: '45vh', overflow: 'auto'}}>
            <AppBar position="sticky" sx={{ backgroundColor: 'transparent', boxShadow: 'none', top: 0, overflow: 'hidden' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiTab-root': {
                            color: 'grey.600',
                            transition: 'color 0.3s, background-color 0.3s',
                        },
                        '& .Mui-selected': {
                            backgroundColor: 'rgba(76, 175, 80, 0.1)', // Light green background for active tab
                            color: 'green', // Green text color for active tab
                        },
                    }}
                >
                    <Tab label="Team Members" />
                    <Tab label="Leads" />
                    <Tab label="Projects" />
                </Tabs>
            </AppBar>
            <Grid width={'73vh'} overflow={'scroll'} minHeight={'35vh'}>
            <TabPanel value={value} index={0}>
                 <TeamMember />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* Leads Content */}
                <Typography variant="h6">List of Leads</Typography>
                <ul>
                    <li>Michael Brown</li>
                    <li>Sarah Davis</li>
                </ul>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/* Projects Content */}
                <Typography variant="h6">Ongoing Projects</Typography>
                <ul>
                    <li>Project Alpha</li>
                    <li>Project Beta</li>
                    <li>Project Gamma</li>
                </ul>
            </TabPanel>
            </Grid>
        </Box>
    );
};

export default TeamTabs;
