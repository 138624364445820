import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, Paper, CircularProgress, Typography } from '@mui/material';

export default function SalesBarChart({
    sales,  // Contains 'salesForEmployee' and 'salesForJuniors'
    data    // Contains 'targetSalesAmount' or similar data
}) {
  const [targetSales, setTargetSales] = React.useState(0); // Target sales value
  const [achievedSales, setAchievedSales] = React.useState(0); // Achieved sales for employee
  const [teamSales, setTeamSales] = React.useState(0); // Achieved sales for juniors
  const [loading, setLoading] = React.useState(true); // Loading state

  React.useEffect(() => {
    // Set target sales from 'data' (assuming 'targetSalesAmount' exists in the API response)
    if (data?.data?.data?.targetSalesAmount) {
      setTargetSales(data?.data?.data?.targetSalesAmount);
    }

    // Calculate total sales for employee from 'salesForEmployee'
    if (sales?.data?.data?.salesForEmployee) {
      const employeeSales = sales.data.data?.salesForEmployee.reduce((total, item) => total + item.sales, 0);
      setAchievedSales(employeeSales);
    }

    // Calculate total sales for juniors from 'salesForJuniors'
    if (sales?.data?.data?.salesForJuniors) {
      const juniorSales = sales.data.data?.salesForJuniors.reduce((total, item) => total + item.sales, 0);
      setTeamSales(juniorSales);
    }

    // Simulate loading completion after a delay
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [sales, data]); // Dependencies on sales and data props

  return (
    <Box>
      <Paper
        elevation={12}
        sx={{
          padding: 1,
          borderRadius: 3,
          background: '#ffffff',
          boxShadow: '0px 15px 45px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: 600,
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        {/* Loading Indicator */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Sales Progress
            </Typography>

            <ApexCharts
              options={{
                chart: {
                  type: 'bar',
                  height: 350,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    barHeight: '50%',
                    borderRadius: 10,
                    distributed: true, // Colors distributed across different bars
                    dataLabels: { position: 'top' },
                  },
                },
                colors: ['#ff6f61', '#4caf50', '#2196f3'], // More vibrant colors: red for target, green for achieved, blue for team
                dataLabels: {
                  enabled: true,
                  style: {
                    fontSize: '14px',
                    colors: ['#000000'], // Black text for data labels
                  },
                  formatter: function (val) {
                    return `₹${val}`;
                  },
                },
                xaxis: {
                  categories: ['Target Sales', 'Achieved Sales (Employee)', 'Team Sales (Juniors)'],
                  labels: {
                    style: {
                      colors: ['#000', '#000', '#000'],
                    },
                  },
                },
                grid: {
                  show: true,
                  borderColor: '#f1f1f1', // Light border color for grid
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return `₹${val}`;
                    },
                  },
                  theme: 'dark',
                },
                fill: {
                  type: 'solid',
                },
              }}
              series={[
                {
                  name: 'Sales',
                  data: [targetSales, achievedSales, teamSales], // Data for Target, Achieved, and Team Sales
                },
              ]}
              type="bar"
              height={350}
            />

            <Typography variant="body2" sx={{ marginTop: 2 }}>
              ₹{achievedSales} / ₹{targetSales} (Employee)
            </Typography>

            {/* For Over-achievement */}
            {achievedSales > targetSales && (
              <Typography variant="body2" sx={{ marginTop: 1, color: 'green' }}>
                🎉 Target Exceeded! {`₹${(achievedSales - targetSales).toFixed(0)} more sales.`}
              </Typography>
            )}

            <Typography variant="body2" sx={{ marginTop: 2 }}>
              ₹{teamSales} (Juniors' Total Sales)
            </Typography>

          </Box>
        )}
      </Paper>
    </Box>
  );
}
