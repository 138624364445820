import React, { useState, useEffect } from "react";
import { Typography, Button, Modal, Box, TextField, Grid, Paper, IconButton, Autocomplete, Snackbar, Alert } from "@mui/material";
import Calender from "./Calendar";
import Chart from "./Chart";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  // Approve icon
import CancelIcon from '@mui/icons-material/Cancel';  // Reject icon
import { green, red, yellow, blue } from '@mui/material/colors';
import { usePost } from "../../../helpers/axios/useApi";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../middlewares/auth";
import Loading from "../../../assets/Loading/Index";
// Mock Profile Data
const Profile = [
  { id: 1, image: "/vite.svg", name: "Bunny", availability: "Available" },
  { id: 2, image: "/vite.svg", name: "Bunny", availability: "Available" },
  { id: 3, image: "/vite.svg", name: "Bunny", availability: "Available" },
];

// Holiday Data
const Holiday = [
  { id: 1, date: "Nov 8, 2024", day: "Friday", name: "Diwali" },
  { id: 2, date: "Dec 25, 2024", day: "Wednesday", name: "Christmas" },
];

// Data for Leave Type Chart
const data = [
   {
    name: "Annual Leave",
    value: 15,
    remaining: 10,
},
{
    name: "Sick Leave",
    value: 5,
    remaining: 2,
},
{
    name: "Casual Leave",
    value: 10,
    remaining: 8,
}
];

// Default Events
const initialEvents = [
  {
    title: "Annual Leave",
    start: new Date(2024, 9, 21),
    end: new Date(2024, 9, 26),
    teamMember: "Benny Chagur",
  },
  {
    title: "Public Holiday",
    start: new Date(2024, 9, 29),
    end: new Date(2024, 9, 30),
    teamMember: "Alice Doe",
  },
];

const Leave = () => {
  const emplyeeId = useParams().id;
  const [openModal, setOpenModal] = useState(false);
  const [leaveData, setLeaveData] = useState({
    leaveType: "",
    date: "",
    reason: "",
    leaveDuration: "",
  });
  const {user} = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading , setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); 
  const [events, setEvents] = useState(initialEvents);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleSubmitLeave = usePost("/employee/leave/create");
  useEffect(() => {
    const savedEvents = JSON.parse(localStorage.getItem("leaveRequests")) || [];
    setEvents([...initialEvents, ...savedEvents]);
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const leaveDetails = {
        ...leaveData,
        employeeId: emplyeeId, // Assuming you have the employeeId variable properly defined elsewhere in your component
      };
      const res = await handleSubmitLeave.mutateAsync(leaveDetails); // Use leaveDetails, not just leaveData

      if (res.data !== null) {
        setLoading(false);
        // Show success message
        setSnackbarMessage("Leave request submitted successfully!");
        setSnackbarSeverity("success");
      } else {
        setLoading(false);
        // Show failure message
        setSnackbarMessage(res?.error?.error || "Failed to submit leave request.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting leave request:", error);
      setSnackbarMessage("An error occurred while submitting the leave request.");
      setSnackbarSeverity("error");
    }

    handleCloseModal(); // Close the modal after submission
    setOpenSnackbar(true); // Open the Snackbar after submission
  };

  const handleEventClick = (event) => {
    if (event.isTemporary) {
      setSelectedEvent(event);
    }
  };

  const handleAccept = () => {
    const updatedEvents = events.map((event) =>
      event === selectedEvent
        ? { ...event, teamMember: "Team Member", isTemporary: false }
        : event
    );
    setEvents(updatedEvents);
    localStorage.setItem("leaveRequests", JSON.stringify(updatedEvents));
    setSelectedEvent(null);
  };

  const handleReject = () => {
    const updatedEvents = events.filter((event) => event !== selectedEvent);
    setEvents(updatedEvents);
    localStorage.setItem("leaveRequests", JSON.stringify(updatedEvents));
    setSelectedEvent(null);
  };

  return (
    <>
      <Grid container spacing={3} flexDirection={"row"}>
       {loading && <Loading/>}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Automatically close after 6 seconds
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
        {/* Leave Management */}
        <Grid item xs={12} md={8} >
          <Paper
            sx={{
              padding: 3,
              backgroundColor: "#f5f5f5",
              borderRadius: 3,
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              height: "82vh",
              ":hover": {
                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Grid container justifyContent="space-between">
 
             { emplyeeId === user._id &&               <Button
                variant="contained"
                sx={{
                  backgroundColor: "#4CAF50",
                  color: "#fff",
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#45a049",
                  },
                }}
                onClick={handleOpenModal}
              >
                Request a Leave
              </Button>}
            </Grid>
            <Grid container spacing={3} mt={1}>
      {data?.map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Paper
            sx={{
              padding: 3,
              backgroundColor: "#fff",
              height: "30vh",  // Ensure Paper height is consistent
              borderRadius: 3,
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
              textAlign: "center",
              transition: "transform 0.3s ease",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Chart data={item} />
          </Paper>
        </Grid>
      ))}
    </Grid>
    <Paper
      sx={{
        padding: 3,
        backgroundColor: "#fff",
        borderRadius: 3,
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",  // 3D shadow effect
        marginTop: 2,
        height: 'auto',  // Adjust height to fit content
        overflowY: "auto",
        transition: "all 0.3s ease-in-out",  // Add transition effect
        ":hover": {
          boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",  // Darker shadow on hover for 3D effect
        },
      }}
    >
      <Grid container spacing={3} sx={{ marginBottom: 2 }}>
        <Grid item xs={2}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Leave Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Status
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Date
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Notes
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Action
          </Typography>
        </Grid>
      </Grid>

      <hr style={{ borderColor: "#e0e0e0", margin: "16px 0" }} />

      {/* Leave request item */}
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Typography variant="body2" fontWeight="medium">
            Annual Leave
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: yellow[200],  // Yellow for Requested status
              color: "black",
              "&:hover": {
                backgroundColor: yellow[400],  // Darker yellow on hover
              },
              borderRadius: 1.5,
              fontWeight: "bold",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",  // 3D shadow on button
              transition: "all 0.3s ease-in-out",
            }}
          >
            Requested
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" fontWeight="medium">
            15 to 18 Aug 2024
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" fontWeight="medium">
            Back to home country
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={1} justifyContent="center">
            {/* Approve button */}
            <Grid item>
              <IconButton
                color="success"
                sx={{
              
                  padding: 0.2,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",  // 3D shadow on icon button
                  transition: "all 0.3s ease-in-out",
                  "&:active": {
                    transform: "scale(0.95)",  // Slight scale on click
                  },
                }}
                aria-label="approve"
              >
                <CheckCircleIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
            {/* Reject button */}
            <Grid item>
              <IconButton
                color="error"
                sx={{
                  
                  padding: 0.2,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",  // 3D shadow on icon button
                  transition: "all 0.3s ease-in-out",
                  "&:active": {
                    transform: "scale(0.95)",  // Slight scale on click
                  },
                }}
                aria-label="reject"
              >
                <CancelIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </Paper>
        </Grid>

   



 

   <Grid item xs={12} md={3.7}>
   <Paper
  sx={{
    backgroundColor: "#fff",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: 0,
    borderRadius: 2.5,
    overflow:'hidden',
    height: '82vh',
    transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
    '&:hover': {
      boxShadow: '0 12px 25px rgba(0, 0, 0, 0.15)', // Deeper shadow on hover
      transform: 'translateY(-7px)', // Lift the Paper component a bit more on hover
    },
  }}
>
  <Typography
    variant="h6"
    sx={{
      mt: -2,
      fontWeight: "bold",
      color: "#00796b", // Dark teal color
      padding: 1,
      fontSize: "16px",
      textAlign: "left",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      position: "sticky",
      top: 0,
      zIndex: 1,
      transition: 'color 0.3s ease', // Smooth color transition
      '&:hover': {
        color: '#004d40', // Darker shade of teal on hover
        textDecoration: 'underline', // Underline on hover for text interaction
      },
    }}
  >
    Upcoming Public Holidays
  </Typography>
  <Grid container direction="column" spacing={2} sx={{ marginTop: 3, padding: 2 }}>
    {Holiday.map((holiday) => (
      <Grid item key={holiday.id}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
            borderRadius: 2,
            background: 'linear-gradient(135deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 1) 100%)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow effect
            transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
            '&:hover': {
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)', // Enhanced shadow on hover
              transform: 'translateY(-4px)', // Lifting effect
              background: 'linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%)', // Lighter background gradient on hover
              cursor: 'pointer', // Pointer cursor on hover for interactivity
            },
          }}
        >
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {holiday.date}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {holiday.day}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {holiday.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
</Paper>

      </Grid>
      </Grid>
      {/* Leave Request Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4,
          backgroundColor: '#fff',
          borderRadius: 2,
          width: 450,
          margin: 'auto',
          marginTop:' 5%',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          transform: 'scale(1)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Typography variant="h5" color="text.primary" fontWeight="bold" sx={{ mb: 2 }}>
          Request Leave
        </Typography>

        {/* Autocomplete for Leave Type */}
        <Autocomplete
          fullWidth
          options={['Casual Leave', 'Sick Leave']}
          value={leaveData.leaveType}
          onChange={(event, newValue) => {
            setLeaveData({ ...leaveData, leaveType: newValue });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Leave Type"
              variant="outlined"
              margin="normal"
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: 3,
                },
              }}
            />
          )}
        />

        {/* Date Picker for Start Date */}
        <TextField
          fullWidth
          label=" Date"
          variant="outlined"
          margin="normal"
          type="date"
          onChange={(e) =>
            setLeaveData({ ...leaveData, date: e.target.value })
          }
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: 3,
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />

    

        {/* Autocomplete for Leave Duration */}
        <Autocomplete
          fullWidth
          options={['Full Day', 'Half Day']}
          value={leaveData.leaveDuration}
          onChange={(event, newValue) => {
            setLeaveData({ ...leaveData, leaveDuration: newValue });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Leave Duration"
              variant="outlined"
              margin="normal"
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: 3,
                },
              }}
            />
          )}
        />

        {/* Reason Text Field */}
        <TextField
          fullWidth
          label="Reason"
          variant="outlined"
          margin="normal"
          onChange={(e) =>
            setLeaveData({ ...leaveData, reason: e.target.value })
          }
          multiline
          rows={4}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: 3,
            },
          }}
        />

        {/* Submit Button */}
        <Button
          variant="contained"
          sx={{
            marginTop: 2,
            backgroundColor: '#4CAF50',
            borderRadius: 3,
            padding: '10px 20px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#45a049',
            },
          }}
          onClick={handleSubmit}
        >
          Submit Leave Request
        </Button>
      </Box>
    </Modal>

      {/* Leave Approval Modal */}
      <Modal open={!!selectedEvent} onClose={() => setSelectedEvent(null)}>
        <Box
          sx={{
            padding: 3,
            backgroundColor: "#fff",
            borderRadius: 3,
            width: 400,
            margin: "auto",
            marginTop: "10%",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" color="text.primary">
            Leave Approval
          </Typography>
          <Typography variant="body2" color="text.primary">
            Leave Type: {selectedEvent?.leaveType}
          </Typography>
          <Typography variant="body2" color="text.primary">
            Start Date: {selectedEvent?.startDate}
          </Typography>
          <Typography variant="body2" color="text.primary">
            End Date: {selectedEvent?.endDate}
          </Typography>
          <Typography variant="body2" color="text.primary">
            Reason: {selectedEvent?.reason}
          </Typography>
          <Button
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
            onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ mt: 2, ml: 2 }}
            onClick={handleReject}
          >
            Reject
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Leave;
