import React  from 'react';
import PayrollChart from './payrollPolicy';
import { Box, Grid } from '@mui/system';
import SalesDonutChart from './payrollePaiChart';
import SalesSummaryCard from './payInfo';
import { useGet, usePost } from '../../../helpers/axios/useApi';
import { useParams } from 'react-router-dom';
function Payrolle() {
  const id = useParams().id
  const {data , isLoading} = useGet('/company/payroll/get', {
    employeeId : id
})

  const {data:sales} = useGet('/company/payroll/total-sales-data', {
      employeeId : id,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
  })
  const {data:salesSelf} = useGet('/company/payroll/total-sales', {
    employeeId : id,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
})
  

  return (
      <>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",

      }}>
         <Box sx={{ display: "flex", flexDirection: "row",gap:"1rem", width: "97%", height: "70vh"}}>
          <Grid width={"65%"}>
          <PayrollChart/>
          </Grid>
          <Grid width={"40%"}>
           <SalesDonutChart sales = {sales} data = {data}/>
          </Grid>
        </Box>
        <Grid sx={{
          mt:"-2rem",
        }}>
          <SalesSummaryCard data = {data} sales = {salesSelf}/>
        </Grid>
        </Box>
      </>
  )
}

export default Payrolle
