import React from "react";
import ReactApexChart from "react-apexcharts";
import { Paper } from "@mui/material";

const Chart = ({ data }) => {
  // Extract values from the passed data
  const totalLeaves = data.value; // Total leaves taken
  const remainingLeaves = data.remaining; // Remaining leaves

  // Define updated color schemes for each leave type
  const colors = {
    "Annual Leave": "#2D7F70",  // Teal Green for Annual Leave
    "Sick Leave": "#FF6F61",     // Coral Red for Sick Leave
    "Casual Leave": "#F9A825",   // Golden Yellow for Casual Leave
    "Remaining Leaves": "#A3D8F4",  // Light Blue for Remaining Leaves
  };

  // Get the color for the used leaves based on the leave type name
  const usedLeavesGradient = colors[data.name] || "#8e44ad"; // Default purple color if no match

  // Chart options and data for ApexCharts
  const options = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,  // Show the toolbar (you can hide it if needed)
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradients: true,  // Add smooth gradient animation on load
      },
    },
    colors: [usedLeavesGradient, colors["Remaining Leaves"]],  // Used and Remaining leaves colors
    labels: ['Used Leaves', 'Remaining Leaves'],  // Sections for used and remaining leaves
    plotOptions: {
      pie: {
        donut: {
          size: '60%',  // Adjust donut size for a more prominent central hole
          background: 'transparent',
        },
      },
    },
    dataLabels: {
      enabled: false,  // Disable data labels
    },
    tooltip: {
      enabled: true,  // Enable tooltips
      followCursor: true,
      offsetX: 10, // Position tooltip slightly below the chart
      style: {
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
      },
      theme: 'dark',  // Dark tooltip theme
    },
    stroke: {
      width: 3,  // Slightly thicker border to enhance separation
      colors: ['#fff', '#fff'],  // White border for the donut segments
    },
    legend: {
      show: true, // Show legend
      position: 'bottom', // Position the legend at the bottom
      horizontalAlign: 'left', // Align legend items horizontally
    },
    dropShadow: {
      enabled: true,
      top: 5,
      left: 5,
      blur: 4,
      opacity: 0.2,
    },
  };

  // Donut chart data series for used and remaining leaves
  const series = [totalLeaves - remainingLeaves, remainingLeaves];  // Used and remaining leaves

  return (
    <div id="chart" style={{ height: '100%', width: '100%' }}>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="100%" // Fill the parent container's height
      />
    </div>
  );
};

export default Chart;
