import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';

const RatingStars = ({ rating, onRate }) => {
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleRatingChange = (num) => {
    onRate(num);
  };

  return (
    <Tooltip
      title={
        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white', margin: '0px', borderRadius: '4px' }}>
          {[1, 2, 3, 4, 5].map(num => (
            <div key={num} style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => handleRatingChange(num)}
                onMouseEnter={() => setHoveredRating(num)}
                onMouseLeave={() => setHoveredRating(null)}
              >
                <StarIcon sx={{ color: num <= (hoveredRating || rating) ? "#ebaa2a" : "disabled" }} />
              </IconButton>
              
            </div>
          ))}
        </div>
      }
      placement="top"
      arrow
    >
      <div
        onMouseEnter={() => setHoveredRating(null)} // Clear hover state when not hovering over rating
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <StarIcon sx={{
          color: rating > 0 ? "#ebaa2a" : "disabled",
          cursor: 'pointer',
          fontSize: '1rem'
        }} />
        <span style={{ marginLeft: '4px', color: rating < 0 ? "#000" : "#aaa" }}>
                {rating}
              </span>
      </div>
    </Tooltip>
  );
};

export default RatingStars;
