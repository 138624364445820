// src/components/ConfirmationDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, message }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: 300, borderRadius: 5 , boxShadow: 24 , padding: 2} }}>
   
    <DialogTitle>Confirm Action</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        onClick={() => {
          onConfirm();
          onClose();
        }}
        sx={{
          color: 'white',
          backgroundColor: '#fa2045',
          '&:hover': {
            backgroundColor: '#f73657',
          },
        }}
      >
        Confirm
      </Button>
    </DialogActions>
    
  </Dialog>
);

export default ConfirmationDialog;
