import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgImage from "../../../assets/images/5040007.jpg";
import Loading from "../../../assets/Loading/Index";
import { Link, useNavigate } from "react-router-dom";
import { usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import { Visibility, VisibilityOff } from '@mui/icons-material';
// Validation schema
const schema = yup.object().shape({
  emailOrPhone: yup.string().required("Email or Phone number is required"),
  password: yup.string().required("Password is required"),
  additionalInfo: yup.string().when("toggle", {
    is: true,
    then: yup.string().required("Additional information is required"),
  }),
});

function LoginPage({ onLoginSuccess }) {
  const { login } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const handleLoginMutate = usePost(toggle ? "/employee/login" : "/user/login");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword); // Toggle the password visibility state
  };
  const handleLogin = async (data) => {
    try {
      const dataSubmit = {
        emailOrPhone: data.emailOrPhone,
        password: data.password,
        ...(toggle && { loginId: data.loginId }), // Add additionalInfo only if toggle is true
      };
      setLoading(true);
      const res = await handleLoginMutate.mutateAsync({ dataSubmit });
      if (res.data !== null) {
        login(res.data.message.data, res.data.message.token);
        setTimeout(() => {
          toast.success("Login successful");
          navigate("/leads");
          setLoading(false);
        }, 2000);
      } else {
        toast.error(res.error.error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("An error occurred");
      setLoading(false);
    }
  };

  const backgroundContainerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 2,
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
  };

  const formContainerStyle = {
    position: "absolute",
    zIndex: 999,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "400px",
    width: "100%",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div style={backgroundContainerStyle}>
      <div style={overlayStyle} />
      {loading && <Loading />}
      <ToastContainer />
      <Container component="main" style={formContainerStyle}>
        <form onSubmit={handleSubmit(handleLogin)} style={{ padding: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="emailOrPhone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Email or Phone Number"
                    error={!!errors.emailOrPhone}
                    helperText={
                      errors.emailOrPhone ? errors.emailOrPhone.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"} // Change input type based on visibility
                    variant="outlined"
                    fullWidth
                    label="Password"
                    autoComplete="current-password"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {toggle && (
              <Grid item xs={12}>
                <Controller
                  name="loginId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Enter your workplace ID"
                      error={!!errors.additionalInfo}
                      helperText={
                        errors.additionalInfo
                          ? errors.additionalInfo.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={toggle}
                    onChange={() => setToggle(!toggle)}
                    color="primary"
                  />
                }
                label="Login in your workplace"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ padding: "10px 20px" }}
              >
                Login
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", textAlign: "center" }}
            >
              <Typography variant="body2">
                Don't have an account? <Link to="/register">Register here</Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default LoginPage;
