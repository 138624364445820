import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { LocationCity, Source } from "@mui/icons-material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
function GridViewLead({ 
  leadId, 
  leadName, 
  email,
  mobile,
  assignee,
  owner,
  reportingManager,
  source,
  createdAt,
  stage,
  newStage,
  type,
  city
}) {
  const typeColors = {
    High: { color: "red", arrow: <ArrowDropUp /> },
    Medium: { color: "orange", arrow: <ArrowDropUp /> },
    Low: { color: "green", arrow: <ArrowDropDown /> },
  };
  


  return (
    <Box
      sx={{
        width: "220px",
        height: "auto",  // Allow dynamic height based on content
        maxHeight: "400px", // Set a max-height to ensure it doesn't grow too large
        padding: "8px",
        
        border: `${stage == "Success" ? "2px" : "1px"} solid ${stage == "Success" ? "green" : "#6c5cfa"}`,
        borderColor :stage === "Create" ? "#3a2f6b" : stage === "Follow Up Required" ? "#36669c" : stage === "Booking" ? "#41a0ae" : stage === "Session" ? "#3ec995" : stage === "Not Interested" ? "#b3aea8" : stage === "DNP - Did Not Pick up" ? "#82591b"  : stage === "Invalid Number" ? "#2e2d2b" : "	#3fbf4a",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        backgroundColor: "white",
        overflowY: "auto", // Add scroll if content overflows
      }}
    >
      <Grid container spacing={1}>
        {/* Lead ID and Name */}
        <Grid item xs={12} sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
              <Tooltip title = {leadName} >
          <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "left",  padding: "7px 12px",
              height: "30px",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap", }}>
            {leadName}
          </Typography></Tooltip>
          <Typography
            sx={{
              fontSize: "12px",
              color: type === "HOT" ? "red" : type === "COLD" ? "blue" : "green",
              display: "flex",
              alignItems: "center",
              
            }}
          >
             {type}
          </Typography>
        </Grid>
        
       

        {/* Assignee */}
        <Grid item xs={12} display={"flex"} gap={"0.5rem"}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              
            }}
          >
            <AssignmentIndIcon sx = {{color:"#4287f5", fontSize:"16px"}}/> {assignee} 
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <AdminPanelSettingsIcon sx = {{color:"#4287f5", fontSize:"16px"}}/> {owner}
          </Typography>
        </Grid>
        
       

        

        {/* Email */}
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
             <LocationCity sx = {{color:"#4287f5", fontSize:"16px"}}/> {city}
          </Typography>
        </Grid>

        {/* Mobile */}
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
             <PhoneIphoneIcon sx = {{color:"#4287f5", fontSize:"16px"}}/> {mobile}
          </Typography>
        </Grid>

        {/* Source */}
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
             <Source sx = {{color:"#4287f5", fontSize:"16px"}}/> {source}
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
}

export default GridViewLead;
