import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, CircularProgress, Typography, Tooltip } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { setLeadData } from '../../../redux/actions/leads/filter';
import Loading from '../../../assets/Loading/Index';
import { CloudUpload, UploadFile } from '@mui/icons-material';

// Utility function to generate camelCase IDs from labels
const generateId = (label) => {
  if (!label) return '';
  const cleanedLabel = label.trim().replace(/\s+/g, ' ');
  return cleanedLabel
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

const convertSpecialKeys = (key) => {
  switch (key.toLowerCase()) {
    case 'id':
      return 'leadId';
    case 'name':
      return 'leadName';
    default:
      return generateId(key);
  }
};

const FileUploadButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    setError(null);

    if (file.type.includes('csv')) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          try {
            const data = result.data;
            processFileData(data);
          } catch (error) {
            console.error('Error processing CSV data:', error);
            setError('Error processing CSV data.');
          } finally {
            setLoading(false);
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          setError('Error parsing CSV file.');
          setLoading(false);
        },
      });
    } else if (file.type.includes('sheet') || file.type.includes('excel')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          processFileData(jsonData);
        } catch (error) {
          console.error('Error processing Excel data:', error);
          setError('Error processing Excel data.');
        } finally {
          setLoading(false);
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setError('Error reading file.');
        setLoading(false);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Unsupported file type. Please upload a CSV or Excel file.');
      setLoading(false);
    }
  };

  const processFileData = (data) => {
    if (data.length === 0) {
      console.warn('No data to process.');
      return;
    }
  
    const headers = data[0];
    const rows = data.slice(1);
  

  
    const result = rows.map((row) =>
      row.reduce((acc, cell, index) => {
        const key = convertSpecialKeys(headers[index]);
        acc[key] = cell;
        return acc;
      }, {})
    );
  
  

  
    // Dispatch updated data
    dispatch(setLeadData(result));
  };  
  return (
    <div>
      <input
        ref={fileInputRef}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileUpload}
      />
      <Tooltip title="Upload File">
      <IconButton onClick={handleButtonClick}>
        <CloudUpload sx={{
          color: "#4287f5",
        }}/>
      </IconButton>
      </Tooltip>
      {loading && (
       <Loading/>
      )}
      {error && (
        <Typography variant="body2" color="error" style={{ marginTop: 16 }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default FileUploadButton;
