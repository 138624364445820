import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmpDetails from "../../components/emplyoee/empDetails";
import { useParams } from "react-router-dom";
import { useGet } from "../../helpers/axios/useApi";
import ProfileNav from "../../components/user/profiveNav";
import CalenderView from "../../components/emplyoee/calenderView";
import EmplyoeeDetailTab from "../../components/emplyoee/tabDetails";
import DailyRecordsTable from "../../components/emplyoee/attendence/tableView";
import CalendarViewAttendence from "../../components/emplyoee/attendence/calenderView";
import Payrolle from "../../components/emplyoee/payrolle";
import Leave from "../../components/emplyoee/leave/Leave";

function EmplyoeeDetails() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("Basic Details");

  const tabs = [
    "Basic Details",
    "Attendance",
    "Leave",
    "Payroll",
    "Work History",
  ];

  const disabledTabs = ["Work History"]; // Define disabled tabs

  const handleTabChange = (tab) => {
    if (!disabledTabs.includes(tab)) { // Check if the tab is not disabled
      setActiveTab(tab);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Basic Details":
        return (
          <Grid  width="100%" gap="1rem" display={"flex"}>
            <Grid item width="55%">
              <EmpDetails />
            </Grid>
            <Grid  width="47%" sx={{ backgroundColor: "white", borderRadius: "10px", padding: "0.3rem" }}>
              <Grid display={"flex"} direction="column" width="100%" >
                <Grid width={"100%"} sx={{ background: 'linear-gradient(135deg, #e3f2fd, #dfdff2)', borderRadius: "10px", padding: "1rem" }}>
                  <CalenderView
                    getTimes={(month, year) => console.log(month, year)}
                    size={{ height: 300, width: "100%" }}
                  />
                </Grid>
                <Grid item maxWidth="77vh" mt="1rem" width="100%" sx={{ background: 'linear-gradient(135deg, #e3f2fd, #dfdff2)', borderRadius: "10px", padding: "1rem" }}>
                  <EmplyoeeDetailTab />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case "Attendance":
        return (
          <Grid container sx={{ display: "flex", width: "100%", gap: "1.3rem", height: "70vh" }}>
            <Grid item width={"40%"} overflow={"hidden"}>
              <DailyRecordsTable />
            </Grid>
            <Grid item width={"55%"}>
              <CalendarViewAttendence size={{ height: 500, width: "100%" }} getTimes={(month, year) => console.log(month, year)}/>
            </Grid>
          </Grid>
        );
      case "Leave":
        return <Grid container sx={{ display: "flex", width: "100%", gap: "1.3rem", height: "70vh" }}>
          <Leave/>
        </Grid>;
      case "Payroll":
        return <div><Payrolle/></div>;
      case "Work History":
        return <div>Work History data goes here.</div>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "93%", gap: "1rem" }}>
      <Box pt={3} sx={{ width: "99%", display: "flex", flexDirection: "column", gap: "2rem" }}>
        <Grid container spacing={2} sx={{ width: "100%", position: "sticky", top: 0, zIndex: 999 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            <ProfileNav />
          </Grid>
        </Grid>
      </Box>
      <Grid
        sx={{
          display: "flex",
          width: "97%",
          height: "2.5rem",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          backgroundColor: "#d2d2d4",
          borderRadius: "50px",
          transition: "background-color 300ms ease",
        }}
      >
        {tabs.map((tab) => (
          <Typography
            key={tab}
            onClick={() => handleTabChange(tab)}
            disabled={activeTab === tab}
            sx={{
              width: "30%",
              cursor: "pointer",
              color: activeTab === tab ? "#fff" : "#000",
              backgroundColor: activeTab === tab ? "#155ed4" : "transparent",
              padding: "8px 15px",
              borderRadius: "50px",
              textAlign: "center",
              fontWeight: "300",
              transition: "background-color 500ms ease, color 500ms ease",
            }}
          >
            {tab}
          </Typography>
        ))}
      </Grid>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
        {renderContent()}
      </Box>
    </Box>
  );
}

export default EmplyoeeDetails;
