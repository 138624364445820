// src/App.js

import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import MapComponent from './map';
import ColumnFilter from '../../components/leads/sub-components/filters';
import ColumnFilterPage from '../../components/leads/sub-components/filters';


const Video = () => {
    
   const locations = [
     { name: 'Location 1', latitude: 23.1151736, longitude: 72.6318347 },
     { name: 'Location 2', latitude: 	23.0115919, longitude: 72.5052771 },
     { name: 'Location 3', latitude: 	23.0277378, longitude: 72.5588626 },
   ];

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: '1rem' , margin:'1rem', height:'70vh'}}>
        <Grid width={'30%'}>
            <ColumnFilterPage />
        </Grid>
        <Grid sx={{
          width: '70%',
          height: '100%',
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}>
        <MapComponent locations={locations} />
        </Grid>
     
    </Box>
  );
};

export default Video;
