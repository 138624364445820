// LeadChangeDialog.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  DialogActions,
  Container,
} from '@mui/material';
import Loading from '../../../assets/Loading/Index';
import { useGet, usePost } from '../../../helpers/axios/useApi';
import { useAuth } from '../../../middlewares/auth';
import FileUpload from '../../uploadFiles';
import FormRenderer from '../forms/renderForm';
import { toast, ToastContainer } from 'react-toastify';


const LeadChangeDialog = ({
  open,
  onClose,
  currentLead,
  destinationStatus,
  onSuccess,
  onError,
  newStage,
  isLoading,
  lead
}) => {
  const [startDate, setStartDate] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [teams, setTeams] = useState([]);
  const [employeesData, setEmployees] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const { mutate: updateLead, isLoading: updateLeadLoading } = usePost('leads/update');
  const fileUploadRef = useRef();
  const [userFields, setUserFields] = useState([]);
  const [link , setLink] = useState('');
  const [assignee, setAssignee] = useState();
 console.log(userFields,'userFields')
  const [descripation, setDescripation] = useState('');
  const {user} = useAuth()
  const { data: employees, isLoading: employeesLoading } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const { data: departments, isLoading: departmentsLoading } = useGet(
    "department/all",
    {},
    {},
    { queryKey: "departments" }
  );
  const { data: teamsData, isLoading: teamsLoading } = useGet(
    `department/team-get/`,
    { departmentId: selectedDepartment },
    {
      enabled: Boolean(selectedDepartment), 
    }
  );
  const { data: tasks, isLoading: taskLoading, refetch } = useGet(
    `leads/task-get`,
    { leadId: currentLead?._id },
  );
  const handleFormChange = (fields) => {
    console.log(fields,'fields')
    const zoomLink = localStorage.getItem('zoomLink')
    const link = JSON.parse(zoomLink)
    const data = link ? {...fields, link} : {...fields}
   
    setUserFields(data);
    console.log(userFields,'userFields')

  };
  const { data: stageForm } = useGet(`/leads/stage-form-get`, { workflowStage:destinationStatus === 'Create' ? 'Calling' : destinationStatus }, { enabled: Boolean(destinationStatus) });
  useEffect(() => {
      setCustomFields(stageForm?.data?.data[0]?.formData)
  },[stageForm])

  useEffect(() => {
    if (departments?.data?.message[0]) {
      setDepartmentsData(departments.data.message[0]);
    }
  }, [departments]);
  useEffect(() => {
    if (employees?.data?.message[0] && selectedTeam) {

      const filteredEmployees = employees.data.message[0].filter((employee) =>
        
        employee.team.some((teamId) => teamId === selectedTeam)
      );

      setEmployees(filteredEmployees);
    }
  }, [employees, selectedTeam]);

  useEffect(() => {
    if (teamsData?.data?.message[0]) {
      setTeams(teamsData.data.message[0]);
    }
  }, [teamsData]);

  
  useEffect(() => {
    if (open) {
      // Reset state when the dialog opens
      setStartDate('');
      setSelectedDepartment('');
      setSelectedTeam('');
      setSelectedEmployee('');
      setErrorMessage('');
    }
  }, [open]);

  useEffect(() => {
    setAssignee(currentLead?.assignee);
},[currentLead?.assignee]);
  const getEmployeeName = (id) => {
    const employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id
    );
    if (employee) {
      return `${employee.name}`;
    }
  };


const handlelink = (link) =>{
     setLink(link)
}
  const handleConfirm = async () => {
   
    const fields = {
       ...userFields,
       Link:link ? link : null,
       descripation: descripation
    }

    console.log(fields,'fields')
    const updateData = {
      _id: currentLead._id,
      newStage: destinationStatus,
      assignee: selectedEmployee ? selectedEmployee : null,
      reportingManager: currentLead?.reportingManager ? currentLead?.reportingManager : getEmployeeName(selectedEmployee)?.reportingManager ? getEmployeeName(selectedEmployee)?.reportingManager : user._id,
      descripation: descripation,
      startAt:startDate,
      customFields: fields,
    };
    console.log(updateData,'updateData')

    try {
      await updateLead({ updateData });
      refetch();
      onSuccess();

      onClose();
    } catch (error) {
      console.error('Error updating lead details:', error);
      setErrorMessage('Failed to update lead. Please try again.');
      onError(error);
    }
  };

  
 

 const  getTaskId = (stage)=>{
      return tasks?.data?.existingTasks?.find((task)=>task.taskType === stage)
 }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.5)',
          width: '100%',
          maxWidth: '800px',
          height: '60vh',
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <ToastContainer/>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: { xs: '18px', sm: '20px' },
          color: 'white',
          backgroundColor: '#4287f5',
          borderRadius: '0px',
        }}
      >
        {currentLead?.leadId} {currentLead?.leadName} - {currentLead?.stage}{' '}
        to {currentLead?.newStage || newStage  ||destinationStatus}
      </DialogTitle>
      {(isLoading || updateLeadLoading) && <Loading />}
      <DialogContent sx={{ mt: 3 }}>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box>
          
          
          <TextField 
            value={descripation}
            onChange={(e) => setDescripation(e.target.value)}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: 2 }}
            label="Description"
          />
          
          <FormRenderer template={customFields} onChange={handleFormChange} leadDetails={lead} sendLink={handlelink}/>
        </Box>
        {user?.role === 'superAdmin' || user?.role === 'Admin' || user?.role === 'Manager'  ? (
 <Box mt={2}>
          
 <Grid display="flex" gap={2} flexWrap="wrap">
  
   <Grid width={'70%'}>
     <Typography
       sx={{
         backgroundColor: '#dcdce0',
         padding: '10px 20px',
         borderRadius: '10px',
         fontSize: '13px',
         mb: 2,
       }}
     >
       Change Assignee (Optional)
     </Typography>
     <FormControl
       fullWidth
       sx={{ mb: 2 }}
       disabled={isLoading}
     >
       <InputLabel>Department</InputLabel>
       <Select
         value={selectedDepartment}
         onChange={(e) => {
           setSelectedDepartment(e.target.value);
           setSelectedTeam('');
           setSelectedEmployee('');
         }}
         disabled={isLoading}
       >
         {departmentsData.map((department) => (
           <MenuItem key={department._id} value={department._id}>
             {department.departmentName}
           </MenuItem>
         ))}
       </Select>
     </FormControl>
     <FormControl
       fullWidth
       sx={{ mb: 2 }}
       disabled={!selectedDepartment || isLoading}
     >
       <InputLabel>Team</InputLabel>
       <Select
         value={selectedTeam}
         onChange={(e) => {
           setSelectedTeam(e.target.value);
           setSelectedEmployee('');
         }}
         disabled={isLoading}
       >
         {teams.map((team) => (
           <MenuItem key={team._id} value={team._id}>
             {team.teamName}
           </MenuItem>
         ))}
       </Select>
     </FormControl>
     <FormControl
       fullWidth
       disabled={!selectedTeam || isLoading}
     >
       <InputLabel>Employee</InputLabel>
       <Select
         value={selectedEmployee}
         onChange={(e) => setSelectedEmployee(e.target.value)}
         disabled={isLoading}
       >
         {employeesData?.map((employee) => (
           <MenuItem key={employee._id} value={employee._id}>
             {employee.name}
           </MenuItem>
         ))}
       </Select>
     </FormControl>

   </Grid>
  
 </Grid>
</Box>
        ) : null }
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadChangeDialog;
