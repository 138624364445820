import React, { useEffect, useRef, useState } from 'react';
import './jquery';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/themes/base/theme.css';
import 'formBuilder';
import { Button, Container } from '@mui/material';
import { useGet, usePost } from '../../../helpers/axios/useApi';

const FormBuilderComponent = ({ workflowStage }) => {
    const fbEditorRef = useRef(null);
    const [formData, setFormData] = useState(null);
    const { mutate: postForm } = usePost('/leads/stage-form-build');
    const { data, refetch } = useGet(`/leads/stage-form-get`, { workflowStage });
    const [isData, setIsData] = useState(false);
    const [isFormCreated, setIsFormCreated] = useState(false);
    // Fetch form data when workflowStage changes
    useEffect(() => {
        if (data?.data?.data[0]?.formData) {
            setFormData(data.data.data[0].formData);
            setIsData(true);
        } else {
            setFormData([]); // Set to empty array if no data
            setIsData(false);
            refetch();
        }
    }, [data, workflowStage, refetch]);

    // Initialize the form builder
    useEffect(() => {
        const fbEditor = fbEditorRef.current;

        if (fbEditor) {
            // Destroy existing instance if it exists
            if ($(fbEditor).data('formBuilder')) {
                $(fbEditor).formBuilder('destroy');
            }

            if (isData) {
                // Only initialize if formData is a non-empty array
                if (Array.isArray(formData) && formData.length > 0) {
                    $(fbEditor).formBuilder({
                        formData: formData
                    });
                } else {
                    setIsData(false);
                    
                }
            } 
        }

        // Cleanup function to destroy the form builder instance
        return () => {
            if ($(fbEditor).data('formBuilder')) {
                $(fbEditor).formBuilder('destroy');
            }
        };
    }, [formData, isData]);

    // Function to handle the creation of a new form
    const handleCreateNew = () => {
        setFormData([]); // Reset form data
        setIsData(false); // Set isData to false
        const fbEditor = fbEditorRef.current;

        // Initialize form builder with an empty form
        if (fbEditor) {
            $(fbEditor).formBuilder({});
        }
        setIsFormCreated(true);
    };

    // Function to save the form data
    const handleSave = async () => {
        const fbEditor = fbEditorRef.current;
        const data = $(fbEditor).formBuilder('getData');
        const form = { workflowStage, data };

        await postForm({ form });

    };

    return (
        <Container style={{ padding: '10px 0', background: '#f2f2f2', zIndex: 1 }}>
            <div id="build-wrap" ref={fbEditorRef} style={{ marginTop: '20px' }} />
          
            {
    !isData && !isFormCreated ? (
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateNew} 
            style={{ marginTop: '10px', marginLeft: '10px' }}
        >
            Create New
        </Button>
    ) : isFormCreated ? (
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            style={{ marginTop: '10px' }}
        >
            Save
        </Button>
    ) : (
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            style={{ marginTop: '10px' }}
        >
            Update Form
        </Button>
    )
}
</Container>
    );
};

export default FormBuilderComponent;
