import React, { useState } from 'react';
import { Box, Grid, Button, Tab, Tabs, IconButton } from '@mui/material';
import { SearchInput } from '../../components/search';
import ProfileNav from '../../components/user/profiveNav';
import FormBuilderComponent from '../../components/leads/forms/formBuilder';
import DrawIcon from '@mui/icons-material/Draw';
import AddColumnModal from '../../components/leads/sub-components/createWorkFlow';
function SetUpFields() {
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const tabs = [
    "Calling",
    "Follow Up Required",
    "Booking",
    "Session",
    "Payment",
    "Success",
    "DNP - Did Not Pick Up",
    "Not Interested",
    "Invalid Number"
  ];

  return (
    <>
      <Box width={'93%'} display={'flex'} flexDirection={'column'} gap={'2rem'}>
        <Box
          pt={1}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
          >
            <Grid item xs={12} md={12} container justifyContent="flex-end">
              <ProfileNav />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mr: "2rem" }}>
          <IconButton onClick={handleOpenModal} sx={{
            color: "#487ae8",
            borderRadius: "10px",
            padding: "0.5rem",
            backgroundColor: "white",
            fontSize: "1rem",
          }}>
                <DrawIcon sx={{ color: "#487ae8" }} /> Create workFlow Form
          </IconButton>
        </Box>
        {/* Tabs for Form Builder */}
        <Tabs 
          value={value} 
          onChange={handleChange} 
          variant="scrollable" 
          scrollButtons="auto" 
          sx={{
            backgroundColor: 'white',
            padding: '0.5rem',
            borderRadius: '25px',
            width: '98%',
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>

        {/* Render the FormBuilderComponent based on the selected tab */}
        <Box sx={{ padding: 2 }}>
          <FormBuilderComponent workflowStage={tabs[value]} key={value} />
        </Box>
        <AddColumnModal open={openModal} onClose={handleCloseModal} />
      </Box>

    </>
  );
}

export default SetUpFields;
